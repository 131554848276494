import React, { useState, useEffect, useMemo, useCallback } from 'react';
import './OnBoarding.scss';
import FormInput from '../components/form/FormInput';
import Select from '../components/form/FormSelect';
import NewSelect from '../components/form/NewFormSelect';
import useSentData from '../services/useSentData';
import { listToOption } from '../services/api';
import Validator, { required, url } from '../services/Validator';
import { isolateError } from '../utils/api';
import Loading from '../components/Loading';
import useLoadedData from '../services/useLoadedData';
import { connect, useDispatch } from 'react-redux';
import {
  authLogout,
  setIsOnBoardingUser,
  updateUser,
} from '../store/actions/auth.actions';
import { destroyProjects } from '../store/actions/project.action';
import { destroyTeam } from '../store/actions/team.actions';
import { Redirect } from 'react-router-dom';
import { onOnboarding, track } from '../services/track';
import { useAlert } from 'react-alert';
import store from '../store';
import '../../node_modules/react-modal-video/scss/modal-video.scss';
import { setSidePanelOpenTab } from '../store/actions/side_panel.action';
import { sidePanelTabs } from '../store/reducers/side_panel.reducer';
import { saveOnBoardingInfoToGoogleSheet } from '../services/onBoaringService';

function OnBoardingFormRaw({
  industries,
  professions,
  referrals,
  purposes,
  updateUser,
}) {
  const request = useSentData();
  const [industry, setIndustry] = useState();
  const [profession, setProfession] = useState();
  const [referral, setReferral] = useState(null);
  const [purpose, setPurpose] = useState(null);
  const [otherReferral, setOtherReferral] = useState('');
  const [otherPurpose, setOtherPurpose] = useState('');
  const [needShowErrors, showErrors] = useState(false);
  const [serverErrors, setServerErrors] = useState({});
  const state = store.getState();
  const user = state.auth.user;
  const alert = useAlert();
  const dispatch = useDispatch();

  const showOtherReferralInput = useMemo(() => referral?.label === 'Other', [
    referral,
  ]);

  const showOtherPurposeInput = useMemo(
    () => purpose?.label?.includes('Other'),
    [purpose]
  );

  const validator = Validator({
    industry: [required],
    profession: [required],
  });
  let validation = validator.validateAll({ industry, profession });

  const submit = useCallback(
    e => {
      e.preventDefault();
      dispatch(setIsOnBoardingUser(true));
      if (!request.loading && validator.isValid()) {
        request
          .send('/api/onboarding', {
            industry: (industry || {}).value,
            profession: (profession || {}).value,
            referral: referral?.value,
            purpose: purpose?.value,
            comment: otherReferral,
            other_purpose: otherPurpose,
          })
          .then(res => {
            if (!res.isAxiosError) {
              updateUser({
                profession: profession.value,
                company: {
                  industry: industry.value,
                },
              });
              const userIndustry = industries.find(
                i => i.value === industry.value
              );
              const userProfession = professions.find(
                p => p.value === profession.value
              );
              onOnboarding({
                id: user.id,
                name: user.full_name,
                email: user.email,
                profession: userProfession.label,
                industry: userIndustry.label,
                referral: referral?.label,
                otherReferral,
                purpose: purpose?.label,
                otherPurpose,
              });
              track('Onboarding form complete');
              alert.success('Successfully saved!');
            } else {
              showErrors(true);
              setServerErrors(res.response.data);
              alert.error(isolateError(res));
            }
          });
      } else {
        showErrors(true);
      }
    },
    [industry, profession, referral, otherReferral, purpose, otherPurpose]
  );

  function setSelectedProfession(option) {
    if (serverErrors.profession) {
      let newServerErrors = { ...serverErrors };
      delete newServerErrors.profession;
      setServerErrors(newServerErrors);
    }
    return setProfession(option);
  }

  function setSelectedIndustry(option) {
    if (serverErrors.industry) {
      let newServerErrors = { ...serverErrors };
      delete newServerErrors.industry;
      setServerErrors(newServerErrors);
    }
    return setIndustry(option);
  }

  return (
    <form onSubmit={submit}>
      {request.loading && <Loading />}
      <div className="inputs-wrapper">
        {/* <NewSelect
          options={professions}
          label={'Profession *'}
          setSelectedOption={setSelectedProfession}
          isValid={
            needShowErrors &&
            (!validation.profession || serverErrors.profession)
          }
        /> */}
        {/* <NewSelect
          options={industries}
          label={'Industry or Niche *'}
          setSelectedOption={setSelectedIndustry}
          isValid={
            needShowErrors && (!validation.industry || serverErrors.industry)
          }
        /> */}
        <Select
          id="profession"
          label="Profession *"
          className="account-select"
          options={professions}
          value={profession}
          onChange={v => {
            if (serverErrors.profession) {
              let newServerErrors = { ...serverErrors };
              delete newServerErrors.profession;
              setServerErrors(newServerErrors);
            }
            return setProfession(v);
          }}
          isValid={
            needShowErrors &&
            (!validation.profession || serverErrors.profession)
          }
        />
        <Select
          id="industry"
          label="Industry or Niche *"
          className="account-select"
          options={industries}
          value={industry}
          onChange={v => {
            if (serverErrors.industry) {
              let newServerErrors = { ...serverErrors };
              delete newServerErrors.industry;
              setServerErrors(newServerErrors);
            }
            return setIndustry(v);
          }}
          isValid={
            needShowErrors && (!validation.industry || serverErrors.industry)
          }
        />
        <Select
          id="referral"
          label="How did you hear about Bramework?"
          className="account-select"
          options={referrals}
          value={referral}
          onChange={setReferral}
        />
        {showOtherReferralInput && (
          <FormInput
            id="other-referral"
            label="Please specify"
            value={otherReferral}
            onChange={setOtherReferral}
          />
        )}
        <Select
          id="purpose"
          label="Why did you sign up for Bramework?"
          className="account-select"
          options={purposes}
          value={purpose}
          onChange={setPurpose}
        />
        {showOtherPurposeInput && (
          <FormInput
            id="other-purpose"
            label="Please specify"
            value={otherPurpose}
            onChange={setOtherPurpose}
          />
        )}
      </div>
      <div className="button-wrapper clearfix">
        {/* <button id="submit" className="btn btn-purple float-right">
          Continue
        </button> */}
        <button
          id="submit"
          className="inline-flex items-center px-8 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 float-right"
        >
          Continue
        </button>
      </div>
    </form>
  );
}

const OnBoardingForm = connect(null, { updateUser })(OnBoardingFormRaw);

function OnBoarding({ logoutHandler }) {
  const lists = useLoadedData('/api/lists');
  let inner;
  if (lists.loading) {
    inner = <Loading />;
  } else if (lists.error) {
    inner = <div className="message error">{isolateError(lists.error)}</div>;
  } else if (lists.data) {
    const industries = listToOption(lists.data.industry_list);
    const professions = listToOption(lists.data.profession_list);
    const referrals = listToOption(lists.data.referrals);
    const purposes = listToOption(lists.data.purposes);

    inner = (
      <OnBoardingForm
        industries={industries}
        professions={professions}
        referrals={referrals}
        purposes={purposes}
      />
    );
  }

  return (
    <div className="onboarding-component">
      <button className="logout" onClick={logoutHandler}>
        Log out
      </button>
      <div className="inner">
        <h1>Tell us more about you.</h1>
        {inner}
      </div>
    </div>
  );
}

// function OnBoardingFormWrapper({
//   isFinished,
//   authLogout,
//   destroyProjects,
//   destroyTeam,
//   setSidePanelOpenTab,
//   selectedProject,
// }) {
//   if (isFinished) {
//     setSidePanelOpenTab(sidePanelTabs.AI_WRITING_TOOLS);
//     saveOnBoardingInfoToGoogleSheet();
//     return (
//       <Redirect
//         to={{
//           pathname: `/project/${selectedProject.id}/generate-first-draft-pro`,
//           // pathname: `/project/${selectedProject.id}/keyword-analyzer`,
//           // state: { page: 'post' },
//         }}
//       />
//     );
//   }
//   function logoutHandler() {
//     destroyProjects();
//     destroyTeam();
//     authLogout();
//   }
//   return <OnBoarding logoutHandler={logoutHandler} />;
// }

// const mapStateToProps = state => {
//   let isFinished;
//   try {
//     isFinished = !!state.auth.user.company;
//   } catch {
//     isFinished = false;
//   }
//   return {
//     isFinished,
//     selectedProject: state.project.selectedProject,
//   };
// };

// export default connect(mapStateToProps, {
//   authLogout,
//   destroyProjects,
//   destroyTeam,
//   setSidePanelOpenTab,
// })(OnBoardingFormWrapper);

function NoOnBoardingForm({
  isFinished,
  setSidePanelOpenTab,
  updateUser,
  selectedProject,
}) {
  const request = useSentData();
  const state = store.getState();
  const user = state.auth.user;
  const alert = useAlert();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isFinished) {
      dispatch(setIsOnBoardingUser(true));
      const data = {
        is_intro: true,
      };
      if (!request.loading && user.id) {
        request.send(`/api/users/${user.id}`, data, 'patch').then(res => {
          if (!res.isAxiosError) {
            updateUser(data);
            onOnboarding({
              id: user.id,
              name: user.full_name,
              email: user.email,
              profession: null,
              industry: null,
              referral: null,
              otherReferral: null,
              purpose: null,
              otherPurpose: null,
            });
            track('Onboarding complete');
            alert.success('Successfully saved!');
          } else {
            alert.error(isolateError(res));
          }
        });
      } else {
        alert.error('Something went wrong, please contact support.');
      }
    }
  }, [isFinished]);

  if (isFinished) {
    dispatch(setIsOnBoardingUser(true));
    setSidePanelOpenTab(sidePanelTabs.AI_WRITING_TOOLS);
    return (
      <Redirect
        to={{
          pathname: `/project/${selectedProject.id}/dashboard`,
        }}
      />
    );
  }

  return (
    <div className="justify-center pt-96">
      <Loading />
      <div className="font-bold text-center w-full">Loading your info...</div>
    </div>
  );
}

const mapStateToProps = state => {
  let isFinished;
  try {
    isFinished = !!state.auth.user.is_intro;
  } catch {
    isFinished = false;
  }
  return {
    isFinished,
    selectedProject: state.project.selectedProject,
  };
};

export default connect(mapStateToProps, {
  setSidePanelOpenTab,
  updateUser,
})(NoOnBoardingForm);
