import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useAlert } from 'react-alert';
import {
  CloudUploadIcon,
  PlayIcon,
  FilmIcon,
  ArrowLeftIcon,
  LinkIcon,
  DocumentTextIcon,
  TemplateIcon,
  ViewGridIcon,
  FontIcon,
  PhotographIcon,
  EmojiHappyIcon,
  SwitchHorizontalIcon,
  SaveIcon,
  PlusIcon,
} from '@heroicons/react/outline';
import FormInput from '../../components/form/FormInput';
import FormContainer from '../GenerateFirstDraft/FormContainer';
import Select from '../../components/form/FormSelect';
import LoadingIcon from '../../components/LoadingIcon';

const VIDEO_STYLES = [
  { value: 'professional', label: 'Professional & Corporate' },
  { value: 'casual', label: 'Casual & Friendly' },
  { value: 'energetic', label: 'Energetic & Dynamic' },
  { value: 'educational', label: 'Educational & Informative' },
];

const VIDEO_DURATIONS = [
  { value: '60', label: '60 seconds (Short-form)' },
  { value: '180', label: '3 minutes (Medium-form)' },
  { value: '600', label: '10 minutes (Long-form)' },
];

const ASPECT_RATIOS = [
  {
    value: '9:16',
    label: 'Portrait (9:16)',
    description: 'Best for Stories, Reels, TikTok',
  },
  {
    value: '1:1',
    label: 'Square (1:1)',
    description: 'Perfect for Instagram feed',
  },
  {
    value: '16:9',
    label: 'Landscape (16:9)',
    description: 'Ideal for YouTube, LinkedIn',
  },
];

const AUTO_LAYOUTS = [
  {
    value: 'single',
    label: 'Single Speaker',
    description: 'Focus on one speaker',
  },
  {
    value: 'dual',
    label: 'Dual View',
    description: 'Split screen for two speakers',
  },
  {
    value: 'multi',
    label: 'Multi-Speaker',
    description: 'Dynamic layout for multiple speakers',
  },
  {
    value: 'presentation',
    label: 'Presentation',
    description: 'Slides with speaker overlay',
  },
];

const FONT_STYLES = [
  {
    value: 'modern',
    label: 'Modern Sans',
    description: 'Clean and contemporary',
  },
  {
    value: 'classic',
    label: 'Classic Serif',
    description: 'Traditional and elegant',
  },
  {
    value: 'creative',
    label: 'Creative Display',
    description: 'Bold and eye-catching',
  },
  {
    value: 'custom',
    label: 'Custom Font',
    description: 'Upload your brand font',
  },
];

const TRANSITION_STYLES = [
  {
    value: 'fade',
    label: 'Smooth Fade',
    description: 'Gentle fade between scenes',
  },
  {
    value: 'slide',
    label: 'Slide',
    description: 'Dynamic sliding transitions',
  },
  { value: 'zoom', label: 'Zoom', description: 'Engaging zoom effects' },
  { value: 'none', label: 'None', description: 'Direct cuts between scenes' },
];

function BlogToVideo() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [inputMethod, setInputMethod] = useState('content');
  const [blogContent, setBlogContent] = useState('');
  const [blogUrl, setBlogUrl] = useState('');
  const [videoStyle, setVideoStyle] = useState('professional');
  const [videoDuration, setVideoDuration] = useState('180');
  const [brandTemplate, setBrandTemplate] = useState({
    aspectRatio: '16:9',
    autoLayout: 'single',
    font: {
      style: 'modern',
      size: 16,
      color: '#FFFFFF',
      customFont: null,
    },
    overlay: {
      logo: null,
      position: 'top-right',
      cta: {
        text: '',
        enabled: false,
      },
    },
    aiEmojis: false,
    transitions: 'fade',
  });
  const alert = useAlert();

  const handleGenerateVideo = () => {
    if (inputMethod === 'content' && !blogContent.trim()) {
      alert.error('Please enter your blog content');
      return;
    }
    if (inputMethod === 'url' && !blogUrl.trim()) {
      alert.error('Please enter a valid blog URL');
      return;
    }
    setLoading(true);
    // TODO: Implement video generation logic
    setTimeout(() => setLoading(false), 2000);
  };

  return (
    <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="bg-white shadow sm:rounded-lg overflow-hidden">
        <div className="px-4 py-5 sm:p-6">
          {/* Header Section */}
          <div className="border-b border-gray-200 pb-5 mb-6">
            <div className="flex justify-between items-center">
              <div className="flex items-center">
                <button
                  onClick={() => history.push('/videos')}
                  className="mr-4 text-gray-400 hover:text-gray-600"
                >
                  <ArrowLeftIcon className="h-6 w-6" />
                </button>
                <div>
                  <h3 className="text-2xl font-bold text-gray-900">
                    Blog to Video Creator
                  </h3>
                  <p className="mt-2 text-sm text-gray-500">
                    Transform your blog posts into engaging videos in minutes
                  </p>
                </div>
              </div>
              <FilmIcon className="h-8 w-8 text-purple-600" />
            </div>
          </div>

          <div className="space-y-8">
            {/* Input Method Selection */}
            <div className="border-b border-gray-200">
              <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                <button
                  onClick={() => setInputMethod('content')}
                  className={`${
                    inputMethod === 'content'
                      ? 'border-purple-500 text-purple-600'
                      : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                  } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm flex items-center`}
                >
                  <DocumentTextIcon className="h-5 w-5 mr-2" />
                  Direct Content
                </button>
                <button
                  onClick={() => setInputMethod('url')}
                  className={`${
                    inputMethod === 'url'
                      ? 'border-purple-500 text-purple-600'
                      : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                  } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm flex items-center`}
                >
                  <LinkIcon className="h-5 w-5 mr-2" />
                  Blog URL
                </button>
              </nav>
            </div>

            {/* Blog Input Section */}
            <FormContainer>
              {inputMethod === 'content' ? (
                <>
                  <label className="block text-sm font-medium text-gray-700">
                    Blog Content <span className="text-red-500">*</span>
                  </label>
                  <div className="mt-2">
                    <textarea
                      rows={6}
                      className="shadow-sm focus:ring-purple-500 focus:border-purple-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      placeholder="Paste your blog post content here..."
                      value={blogContent}
                      onChange={e => setBlogContent(e.target.value)}
                    />
                  </div>
                </>
              ) : (
                <>
                  <label className="block text-sm font-medium text-gray-700">
                    Blog URL <span className="text-red-500">*</span>
                  </label>
                  <div className="mt-2">
                    <div className="flex rounded-md shadow-sm">
                      <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
                        <LinkIcon className="h-4 w-4" />
                      </span>
                      <input
                        type="url"
                        className="flex-1 min-w-0 block w-full px-3 py-2 rounded-none rounded-r-md focus:ring-purple-500 focus:border-purple-500 sm:text-sm border-gray-300"
                        placeholder="https://yourblog.com/post-title"
                        value={blogUrl}
                        onChange={e => setBlogUrl(e.target.value)}
                      />
                    </div>
                    <p className="mt-2 text-sm text-gray-500">
                      Enter the full URL of your blog post
                    </p>
                  </div>
                </>
              )}
            </FormContainer>

            {/* Video Style & Duration */}
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
              <FormContainer>
                <label className="block text-sm font-medium text-gray-700">
                  Video Style <span className="text-red-500">*</span>
                </label>
                <Select
                  value={videoStyle}
                  onChange={value => setVideoStyle(value)}
                  options={VIDEO_STYLES}
                  className="mt-2"
                />
              </FormContainer>

              <FormContainer>
                <label className="block text-sm font-medium text-gray-700">
                  Video Duration <span className="text-red-500">*</span>
                </label>
                <Select
                  value={videoDuration}
                  onChange={value => setVideoDuration(value)}
                  options={VIDEO_DURATIONS}
                  className="mt-2"
                />
              </FormContainer>
            </div>

            {/* Brand Template Section */}
            <div className="border-t border-gray-200 pt-8">
              <div className="flex justify-between items-center mb-6">
                <div>
                  <h3 className="text-lg font-medium text-gray-900">
                    Brand Template
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">
                    Customize your video's look and feel to match your brand
                    identity
                  </p>
                </div>
                <button
                  type="button"
                  onClick={() =>
                    alert.info('Template saving will be implemented soon!')
                  }
                  className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                >
                  <SaveIcon className="h-4 w-4 mr-2" />
                  Save Template
                </button>
              </div>

              <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-2 lg:grid-cols-3">
                {/* Aspect Ratio */}
                <FormContainer>
                  <label className="block text-sm font-medium text-gray-700">
                    Aspect Ratio
                  </label>
                  <Select
                    value={brandTemplate.aspectRatio}
                    onChange={value =>
                      setBrandTemplate(prev => ({
                        ...prev,
                        aspectRatio: value,
                      }))
                    }
                    options={ASPECT_RATIOS}
                    className="mt-1"
                  />
                </FormContainer>

                {/* Auto Layout */}
                <FormContainer>
                  <label className="block text-sm font-medium text-gray-700">
                    Layout Style
                  </label>
                  <Select
                    value={brandTemplate.autoLayout}
                    onChange={value =>
                      setBrandTemplate(prev => ({ ...prev, autoLayout: value }))
                    }
                    options={AUTO_LAYOUTS}
                    className="mt-1"
                  />
                </FormContainer>

                {/* Font Style */}
                <FormContainer>
                  <label className="block text-sm font-medium text-gray-700">
                    Caption Style
                  </label>
                  <Select
                    value={brandTemplate.font.style}
                    onChange={value =>
                      setBrandTemplate(prev => ({
                        ...prev,
                        font: { ...prev.font, style: value },
                      }))
                    }
                    options={FONT_STYLES}
                    className="mt-1"
                  />
                  {brandTemplate.font.style === 'custom' && (
                    <div className="mt-2">
                      <button
                        type="button"
                        onClick={() =>
                          alert.info('Font upload will be implemented soon!')
                        }
                        className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                      >
                        <PlusIcon className="h-4 w-4 mr-2" />
                        Upload Font
                      </button>
                    </div>
                  )}
                </FormContainer>

                {/* Font Customization */}
                <FormContainer>
                  <label className="block text-sm font-medium text-gray-700">
                    Caption Customization
                  </label>
                  <div className="mt-2 space-y-4">
                    <div>
                      <label className="text-xs text-gray-500">Size</label>
                      <input
                        type="range"
                        min="12"
                        max="32"
                        value={brandTemplate.font.size || 16}
                        onChange={e => {
                          const value = e?.target?.value
                            ? parseInt(e.target.value, 10)
                            : 16;
                          setBrandTemplate(prev => ({
                            ...prev,
                            font: { ...prev.font, size: value },
                          }));
                        }}
                        className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
                      />
                      <span className="text-xs text-gray-500">
                        {brandTemplate.font.size || 16}px
                      </span>
                    </div>
                    <div>
                      <label className="text-xs text-gray-500">Color</label>
                      <input
                        type="color"
                        value={brandTemplate.font.color}
                        onChange={e =>
                          setBrandTemplate(prev => ({
                            ...prev,
                            font: { ...prev.font, color: e.target.value },
                          }))
                        }
                        className="w-full h-8 rounded cursor-pointer"
                      />
                    </div>
                  </div>
                </FormContainer>

                {/* Static Overlay */}
                <FormContainer>
                  <label className="block text-sm font-medium text-gray-700">
                    Brand Overlay
                  </label>
                  <div className="mt-2 space-y-4">
                    <div className="flex items-center justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md hover:border-purple-500 transition-colors duration-200">
                      <div className="space-y-1 text-center">
                        <PhotographIcon className="mx-auto h-8 w-8 text-gray-400" />
                        <div className="flex text-sm text-gray-600">
                          <label className="relative cursor-pointer bg-white rounded-md font-medium text-purple-600 hover:text-purple-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-purple-500">
                            <span>Upload Logo</span>
                            <input
                              type="file"
                              className="sr-only"
                              accept="image/*"
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                    <Select
                      value={brandTemplate.overlay.position}
                      onChange={value =>
                        setBrandTemplate(prev => ({
                          ...prev,
                          overlay: { ...prev.overlay, position: value },
                        }))
                      }
                      options={[
                        { value: 'top-left', label: 'Top Left' },
                        { value: 'top-right', label: 'Top Right' },
                        { value: 'bottom-left', label: 'Bottom Left' },
                        { value: 'bottom-right', label: 'Bottom Right' },
                      ]}
                    />
                  </div>
                </FormContainer>

                {/* AI Features */}
                <FormContainer>
                  <label className="block text-sm font-medium text-gray-700">
                    AI Enhancements
                  </label>
                  <div className="mt-4 space-y-4">
                    <div className="flex items-center">
                      <input
                        type="checkbox"
                        checked={brandTemplate.aiEmojis}
                        onChange={e =>
                          setBrandTemplate(prev => ({
                            ...prev,
                            aiEmojis: e.target.checked,
                          }))
                        }
                        className="h-4 w-4 text-purple-600 focus:ring-purple-500 border-gray-300 rounded"
                      />
                      <label className="ml-3 text-sm text-gray-600">
                        Auto-generate relevant emojis
                      </label>
                    </div>
                  </div>
                </FormContainer>

                {/* Transitions */}
                <FormContainer>
                  <label className="block text-sm font-medium text-gray-700">
                    Scene Transitions
                  </label>
                  <Select
                    value={brandTemplate.transitions}
                    onChange={value =>
                      setBrandTemplate(prev => ({
                        ...prev,
                        transitions: value,
                      }))
                    }
                    options={TRANSITION_STYLES}
                    className="mt-1"
                  />
                </FormContainer>
              </div>
            </div>

            {/* Generate Button */}
            <div className="flex justify-end space-x-4 pt-4 border-t border-gray-200">
              <button
                type="button"
                onClick={handleGenerateVideo}
                disabled={
                  loading ||
                  (inputMethod === 'content'
                    ? !blogContent.trim()
                    : !blogUrl.trim())
                }
                className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 disabled:opacity-50 disabled:cursor-not-allowed transition-all duration-200"
              >
                {loading ? (
                  <>
                    <LoadingIcon className="h-5 w-5 mr-2" />
                    Generating Video...
                  </>
                ) : (
                  <>
                    <PlayIcon className="h-5 w-5 mr-2" />
                    Generate Video
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlogToVideo;
