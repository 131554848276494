import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import store from './store';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/browser';
// import { hotjar } from 'react-hotjar';
import { authCheck } from './store/actions/auth.actions';
import { loadCollapseState } from './store/actions/menu.action';
import { loadListViewState } from './store/actions/layout.actions';
import { loadPanelCollapseState } from './store/actions/side_panel.action';
// import { loadPlagiarismPanelCollapseState } from './store/actions/plagiarism_panel.action';
import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import AlertTemplate from './components/AlertTemplate';

window.mixpanel.init(process.env.REACT_APP_MIXPANEL_KEY, {
  record_sessions_percent: 100,
  record_block_selector: '',
  record_mask_text_selector: '.sensitive',
});
if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
  });
}
// if (process.env.REACT_APP_HOTJAR_ID) {
//   hotjar.initialize(process.env.REACT_APP_HOTJAR_ID, '');
// }

if (process.env.REACT_APP_INTERCOM_ID) {
  window.Intercom('boot', {
    app_id: process.env.REACT_APP_INTERCOM_ID,
  });
}

store.dispatch(authCheck());
store.dispatch(loadCollapseState());
store.dispatch(loadListViewState());
store.dispatch(loadPanelCollapseState());
// store.dispatch(loadPlagiarismPanelCollapseState());

const options = {
  position: positions.TOP_RIGHT,
  timeout: 5000,
  offset: '0 30px 10px 30px',
  transition: transitions.FADE,
};

ReactDOM.render(
  <Provider store={store}>
    <AlertProvider template={AlertTemplate} {...options}>
      <Router>
        <App />
      </Router>
    </AlertProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
