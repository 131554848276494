import './GenerateFirstDraftPro.scss';
import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useAlert } from 'react-alert';
import { track } from '../../services/track';
import useSentData from '../../services/useSentData';
import http from '../../services/http';
import { updateUser } from '../../store/actions/auth.actions';
import {
  ArrowLeftIcon,
  CloudUploadIcon,
  PlusIcon,
} from '@heroicons/react/outline';

import FormInput from '../../components/form/FormInput';
import LoadingIcon from '../../components/LoadingIcon';
import FormContainer from './FormContainer';
import FormLabel from './FormLabel';
import Select from '../../components/form/FormSelect';
import { TONES } from '../../utils/common';
import DraftGenerationModal from '../../components/DraftGenerationModal';

const MAX_KEYWORDS = 50;
const KEYWORD_INPUT_METHODS = {
  CSV: 'csv',
  MANUAL: 'manual',
};

const BulkFirstDraftPro = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { project_id } = useParams();
  const alert = useAlert();
  const request = useSentData();
  const user = useSelector(state => state.auth.user);

  const [inputMethod, setInputMethod] = useState(KEYWORD_INPUT_METHODS.MANUAL);
  const [keywords, setKeywords] = useState([]);
  const [newKeyword, setNewKeyword] = useState('');
  const [tone, setTone] = useState('informative');
  const [audience, setAudience] = useState('');
  const [loading, setLoading] = useState(false);
  const [isDraftGenerationModalOpen, setIsDraftGenerationModalOpen] = useState(
    false
  );
  const [firstDraftCredits, setFirstDraftCredits] = useState(
    user.first_draft_credits || 0
  );
  const [addonFirstDraftCredits, setAddonFirstDraftCredits] = useState(
    user.addon_first_draft_credits || 0
  );

  const getRemainingCredits = useCallback(() => {
    http.get(`/api/users/${user.id}`).then(userRes => {
      const data = userRes.data;
      if (!data.isAxiosError) {
        dispatch(updateUser(data));
        setFirstDraftCredits(data.first_draft_credits || 0);
        setAddonFirstDraftCredits(data.addon_first_draft_credits || 0);
      }
    });
  }, [user.id, dispatch]);

  useEffect(() => {
    getRemainingCredits();
  }, [getRemainingCredits]);

  const handleFileUpload = event => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = e => {
        const text = e.target.result;
        const uploadedKeywords = text
          .split('\n')
          .map(k => k.trim())
          .filter(k => k.length > 0)
          .slice(0, MAX_KEYWORDS);
        setKeywords(uploadedKeywords);
      };
      reader.readAsText(file);
    }
  };

  const handleAddKeyword = () => {
    if (newKeyword.trim()) {
      const keywordsList = newKeyword
        .split(',')
        .map(k => k.trim())
        .filter(k => k.length > 0);

      const uniqueKeywords = [...new Set([...keywords, ...keywordsList])];

      if (uniqueKeywords.length > MAX_KEYWORDS) {
        alert.error(`You can only add up to ${MAX_KEYWORDS} keywords`);
        return;
      }

      setKeywords(uniqueKeywords);
      setNewKeyword('');
    }
  };

  const handleRemoveKeyword = index => {
    setKeywords(keywords.filter((_, i) => i !== index));
  };

  const handleGenerateBulkDrafts = async () => {
    if (keywords.length === 0) {
      alert.error('Please add at least one keyword');
      return;
    }

    if (keywords.length > MAX_KEYWORDS) {
      alert.error(`Maximum ${MAX_KEYWORDS} keywords allowed`);
      return;
    }

    if (firstDraftCredits + addonFirstDraftCredits < keywords.length) {
      alert.error('Not enough First Draft PRO credits');
      return;
    }

    setLoading(true);

    try {
      const res = await request.send('/api/generate-bulk-drafts-pro', {
        keywords,
        tone,
        audience,
        project_id,
      });

      if (res.success) {
        alert.success(res.message);
        setIsDraftGenerationModalOpen(true);
        getRemainingCredits();
      } else {
        alert.error(res.message);
      }
    } catch (err) {
      alert.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleGoToDashboard = () => {
    track('Bulk First Draft PRO generator', { Clicked: 'go to dashboard' });
    setKeywords([]);
    setNewKeyword('');
    history.push(`/project/${project_id}/drafts`);
  };

  const handleKeyDown = e => {
    if (e.key === 'Enter' && newKeyword.trim()) {
      e.preventDefault();
      handleAddKeyword();
    }
  };

  return (
    <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="bg-white shadow sm:rounded-lg overflow-hidden">
        <div className="px-4 py-5 sm:p-6">
          <div className="border-b border-gray-200 pb-5 mb-6">
            <div className="flex justify-between items-center">
              <div>
                <h3 className="text-2xl font-bold text-gray-900">
                  Bulk First Draft PRO
                </h3>
                <p className="mt-2 text-sm text-gray-500">
                  Generate multiple blog post drafts at once by providing
                  keywords and preferences.
                </p>
                <div className="mt-2">
                  <span className="inline-flex items-center px-2.5 py-0.5 rounded-md text-xs font-medium bg-blue-100 text-blue-600">
                    Coming Soon
                  </span>
                </div>
              </div>
              <button
                onClick={handleGoToDashboard}
                className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
              >
                <ArrowLeftIcon className="h-5 w-5 mr-2" />
                Back to Dashboard
              </button>
            </div>
          </div>

          <div className="space-y-8">
            {/* Input Method Selection */}
            <FormContainer>
              <label className="block text-sm font-medium text-gray-700">
                Choose Input Method
              </label>
              <div className="mt-3">
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                  <div
                    className="relative flex items-center p-4 border rounded-lg cursor-pointer hover:bg-gray-50 transition-colors duration-200"
                    onClick={() => setInputMethod(KEYWORD_INPUT_METHODS.MANUAL)}
                  >
                    <input
                      type="radio"
                      name="input-method"
                      value={KEYWORD_INPUT_METHODS.MANUAL}
                      checked={inputMethod === KEYWORD_INPUT_METHODS.MANUAL}
                      onChange={e => setInputMethod(e.target.value)}
                      className="focus:ring-purple-500 h-4 w-4 text-purple-600 border-gray-300"
                    />
                    <label className="ml-3 block">
                      <span className="text-sm font-medium text-gray-900">
                        Manual Entry
                      </span>
                      <span className="block text-xs text-gray-500 mt-1">
                        Add keywords one by one
                      </span>
                    </label>
                  </div>
                  <div
                    className="relative flex items-center p-4 border rounded-lg cursor-pointer hover:bg-gray-50 transition-colors duration-200"
                    onClick={() => setInputMethod(KEYWORD_INPUT_METHODS.CSV)}
                  >
                    <input
                      type="radio"
                      name="input-method"
                      value={KEYWORD_INPUT_METHODS.CSV}
                      checked={inputMethod === KEYWORD_INPUT_METHODS.CSV}
                      onChange={e => setInputMethod(e.target.value)}
                      className="focus:ring-purple-500 h-4 w-4 text-purple-600 border-gray-300"
                    />
                    <label className="ml-3 block">
                      <span className="text-sm font-medium text-gray-900">
                        CSV Upload
                      </span>
                      <span className="block text-xs text-gray-500 mt-1">
                        Import keywords from a file
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </FormContainer>

            {/* CSV Upload */}
            {inputMethod === KEYWORD_INPUT_METHODS.CSV && (
              <FormContainer>
                <div className="mt-2 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-lg">
                  <div className="space-y-1 text-center">
                    <CloudUploadIcon className="mx-auto h-12 w-12 text-gray-400" />
                    <div className="flex text-sm text-gray-600">
                      <label className="relative cursor-not-allowed bg-white rounded-md font-medium text-gray-400">
                        <span>Upload a file</span>
                        <input
                          type="file"
                          className="sr-only"
                          onChange={handleFileUpload}
                          disabled
                        />
                      </label>
                    </div>
                    <p className="text-xs text-gray-500">CSV up to 10MB</p>
                  </div>
                </div>
              </FormContainer>
            )}

            {/* Manual Keyword Entry */}
            {inputMethod === KEYWORD_INPUT_METHODS.MANUAL && (
              <FormContainer>
                <label className="block text-sm font-medium text-gray-700">
                  Add Keywords <span className="text-red-500">*</span>
                </label>
                <div className="mt-2">
                  <div className="flex">
                    <FormInput
                      value={newKeyword}
                      onChange={value => setNewKeyword(value)}
                      className="flex-1"
                      inputProps={{
                        placeholder:
                          'Enter keywords separated by commas (e.g., how to start a blog, make money online)',
                        onKeyDown: e => e.preventDefault(),
                        disabled: true,
                      }}
                    />
                    <button
                      type="button"
                      onClick={handleAddKeyword}
                      disabled
                      className="ml-3 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-gray-400 cursor-not-allowed"
                    >
                      <PlusIcon className="h-5 w-5 mr-2" />
                      Add
                    </button>
                  </div>
                  <p className="mt-2 text-xs text-gray-500">
                    Press Enter to quickly add keywords. Maximum {MAX_KEYWORDS}{' '}
                    keywords allowed.
                  </p>
                </div>
              </FormContainer>
            )}

            {/* Keywords List */}
            {keywords.length > 0 && (
              <FormContainer>
                <div className="flex items-center justify-between">
                  <label className="block text-sm font-medium text-gray-700">
                    Keywords ({keywords.length}/{MAX_KEYWORDS})
                  </label>
                  <button
                    onClick={() => setKeywords([])}
                    className="text-sm text-red-600 hover:text-red-800 transition-colors duration-200"
                  >
                    Clear All
                  </button>
                </div>
                <div className="mt-2 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-2">
                  {keywords.map((keyword, index) => (
                    <div
                      key={index}
                      className="flex items-center justify-between p-2 bg-gray-50 rounded-md border border-gray-200 group hover:border-purple-200 transition-colors duration-200"
                    >
                      <span className="text-sm text-gray-700 truncate">
                        {keyword}
                      </span>
                      <button
                        type="button"
                        onClick={() => handleRemoveKeyword(index)}
                        className="text-gray-400 hover:text-red-600 opacity-0 group-hover:opacity-100 transition-opacity duration-200"
                      >
                        <svg
                          className="h-4 w-4"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </button>
                    </div>
                  ))}
                </div>
              </FormContainer>
            )}

            {/* Tone Selection */}
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
              <FormContainer>
                <label className="block text-sm font-medium text-gray-700">
                  Tone <span className="text-red-500">*</span>
                </label>
                <Select
                  value={tone}
                  onChange={value => setTone(value)}
                  options={TONES}
                  className="mt-2"
                />
              </FormContainer>

              {/* Audience */}
              <FormContainer>
                <label className="block text-sm font-medium text-gray-700">
                  Target Audience{' '}
                  <span className="text-gray-500">(Optional)</span>
                </label>
                <FormInput
                  value={audience}
                  onChange={value => setAudience(value)}
                  className="mt-2"
                  inputProps={{
                    placeholder: 'e.g., Beginner bloggers 25-45',
                  }}
                />
              </FormContainer>
            </div>

            {/* Credits Information */}
            <div className="bg-gradient-to-r from-purple-50 to-purple-100 p-6 rounded-lg">
              <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center space-y-2 sm:space-y-0">
                <div className="flex flex-col">
                  <span className="text-sm font-medium text-gray-900">
                    Credits Available
                  </span>
                  <span className="text-2xl font-bold text-purple-600">
                    {firstDraftCredits + addonFirstDraftCredits}
                  </span>
                </div>
                <div className="flex flex-col text-right">
                  <span className="text-sm font-medium text-gray-900">
                    Required Credits
                  </span>
                  <span
                    className={`text-2xl font-bold ${
                      keywords.length >
                      firstDraftCredits + addonFirstDraftCredits
                        ? 'text-red-600'
                        : 'text-green-600'
                    }`}
                  >
                    {keywords.length}
                  </span>
                </div>
              </div>
            </div>

            {/* Generate Button */}
            <div className="flex justify-end space-x-4 pt-4 border-t border-gray-200">
              <button
                type="button"
                onClick={handleGenerateBulkDrafts}
                disabled
                className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-gray-400 cursor-not-allowed"
              >
                {loading ? (
                  <>
                    <LoadingIcon className="h-5 w-5 mr-2" />
                    Generating...
                  </>
                ) : (
                  'Generate Blog Posts'
                )}
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Draft Generation Modal */}
      <DraftGenerationModal
        isOpen={isDraftGenerationModalOpen}
        onClose={() => setIsDraftGenerationModalOpen(false)}
        onGoToDashboard={handleGoToDashboard}
        onCreateAnother={() => {
          setIsDraftGenerationModalOpen(false);
          setKeywords([]);
          setNewKeyword('');
        }}
      />
    </div>
  );
};

export default BulkFirstDraftPro;
