// ContentSection.js
import React, { useState } from 'react';
import { ClipboardCopyIcon } from '@heroicons/react/solid';
import CollapsibleSection from './CollapsibleSection';
import CopyableSection from './CopyableSection';

const ContentSection = ({ title, subsections }) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyAll = async () => {
    try {
      const styledContent = `${title}\n\n${subsections
        .map(subsection => {
          // Strip HTML tags for copying, but preserve text content
          const textContent = subsection.content.replace(/<[^>]+>/g, '');
          return `${subsection.title}\n\n${textContent}`;
        })
        .join('\n\n')}`;
      await navigator.clipboard.writeText(styledContent);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  const processedSubsections = subsections.map(subsection => {
    let processedContent = subsection.content;

    // If this is the Sources section, wrap URLs in a list
    if (subsection.title === 'Sources:' || subsection.title === 'Sources') {
      // Extract unique URLs from the content
      const urlMatches = [
        ...processedContent.matchAll(/(https?:\/\/[^\s<>"]+)/g),
      ];
      const uniqueUrls = [...new Set(urlMatches.map(match => match[1]))];

      processedContent = `<ul class="list-none space-y-2">${uniqueUrls
        .map(
          url =>
            `<li><a href="${url}" target="_blank" rel="noopener noreferrer" class="text-purple-600 hover:text-purple-800">${url}</a></li>`
        )
        .join('')}</ul>`;
    } else {
      // For non-source sections, handle source links and URLs
      processedContent = processedContent
        // First handle the source links
        .replace(
          /<a href="([^"]+)".*?>Source<\/a>/g,
          '<a href="$1" target="_blank" rel="noopener noreferrer" class="text-purple-600 hover:text-purple-800">Source</a>'
        )
        // Then handle any remaining URLs that aren't already in an anchor tag
        .replace(
          /(?<!href=["'])(https?:\/\/[^\s<>"]+)(?![^<]*>)/g,
          '<a href="$1" target="_blank" rel="noopener noreferrer" class="text-purple-600 hover:text-purple-800">$1</a>'
        );
    }

    return {
      ...subsection,
      content: processedContent,
    };
  });

  return (
    <CollapsibleSection title={title}>
      <div className="mb-4">
        <button
          onClick={handleCopyAll}
          className="flex items-center text-sm text-purple-600 hover:text-purple-800"
        >
          <ClipboardCopyIcon className="w-4 h-4 mr-1" />
          {isCopied ? 'All Copied!' : 'Copy All'}
        </button>
      </div>
      {processedSubsections.map((subsection, index) => (
        <CopyableSection
          key={index}
          title={subsection.title}
          content={subsection.content}
        />
      ))}
    </CollapsibleSection>
  );
};

export default ContentSection;
