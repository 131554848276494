import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  ChatAlt2Icon,
  DocumentTextIcon,
  DocumentSearchIcon,
  SparklesIcon,
  SwitchHorizontalIcon,
  PencilAltIcon,
  NewspaperIcon,
  TemplateIcon,
  KeyIcon,
  AdjustmentsIcon,
  ShareIcon,
  CollectionIcon,
  VideoCameraIcon,
} from '@heroicons/react/outline';
import { track } from '../services/track';

function StartHere({ selectedProjectId }) {
  const { state } = useLocation();
  const history = useHistory();
  const user = useSelector(state => state.auth?.user);

  const handleUserOnboardingClick = (route, page, type) => () => {
    if (selectedProjectId && route !== 'chat' && route !== 'videos') {
      history.push(`/project/${selectedProjectId}/${route}`, {
        page,
      });
    } else {
      history.push(`/${route}`);
    }
    if (state?.isOnBoardingUser) {
      track('Click dashboard button', { Clicked: type });
    }
  };

  return (
    <div className="p-8 rounded-lg text-gray-700">
      <h2 className="text-xl md:text-2xl font-bold text-center mb-4">
        Blogging Essentials
      </h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {/* First Draft PRO Card */}
        <div
          onClick={handleUserOnboardingClick(
            'generate-first-draft-pro',
            'draft-pro',
            'New First Draft PRO'
          )}
          className="relative flex flex-col items-center p-4 border border-purple-300 hover:border-purple-700 rounded-lg m-0.5 shadow-2xl transform hover:bg-gray-100 hover:scale-105 transition-all duration-200 ease-in-out w-full md:w-auto cursor-pointer"
        >
          <span className="absolute top-0 right-0 mt-2 mr-2 inline-flex items-center bg-green-100 text-green-700 text-xs font-bold px-3 py-1.5 rounded-md">
            Popular
          </span>
          <DocumentSearchIcon className="w-12 h-12 mb-3 text-purple-500" />
          <h3 className="mb-2 font-semibold">First Draft PRO</h3>
          <p className="text-sm text-center">
            With a single click, generate factual, researched, and SEO-optimized
            blog posts complete with key takeaways, citations, and more.
          </p>
        </div>

        {/* Blog Booster Card */}
        <div
          onClick={handleUserOnboardingClick(
            'blog-post-improver',
            'post-improver',
            'Blog Post Improver'
          )}
          className="relative flex flex-col items-center p-4 border border-gray-200 hover:border-purple-700 rounded-lg m-2 shadow-lg transform hover:bg-gray-100 hover:scale-105 transition-all duration-200 ease-in-out w-full md:w-auto cursor-pointer"
        >
          <span className="absolute top-0 right-0 mt-2 mr-2 inline-flex items-center bg-green-100 text-green-700 text-xs font-bold px-3 py-1.5 rounded-md">
            Popular
          </span>
          <AdjustmentsIcon className="w-12 h-12 mb-3 text-purple-500" />
          <h3 className="mb-2 font-semibold">Blog Booster</h3>
          <p className="text-sm text-center">
            Improve your blog posts with our blog improver tool. Receive
            actionable insights to enhance your writing and increase your
            organic traffic.
          </p>
        </div>

        {/* Video Creation Card */}
        <div
          onClick={handleUserOnboardingClick('videos', 'videos', 'New Video')}
          className="relative flex flex-col items-center p-4 border border-gray-200 hover:border-purple-700 rounded-lg m-2 shadow-lg transform hover:bg-gray-100 hover:scale-105 transition-all duration-200 ease-in-out w-full md:w-auto cursor-pointer"
        >
          <span className="absolute top-0 right-0 mt-2 mr-2 inline-flex items-center bg-blue-100 text-blue-700 text-xs font-bold px-3 py-1.5 rounded-md">
            Coming Soon
          </span>
          <VideoCameraIcon className="w-12 h-12 mb-3 text-purple-500" />
          <h3 className="mb-2 font-semibold">Video Creator</h3>
          <p className="text-sm text-center">
            Transform your blog content into engaging videos with our AI-powered
            video creation tool.
          </p>
        </div>

        {/* New Card for Bulk First Draft PRO */}
        <div
          onClick={handleUserOnboardingClick(
            'bulk-first-draft-pro',
            'bulk-draft-pro',
            'New Bulk First Draft PRO'
          )}
          className="relative flex flex-col items-center p-4 border border-gray-200 hover:border-purple-700 rounded-lg m-2 shadow-lg transform hover:bg-gray-100 hover:scale-105 transition-all duration-200 ease-in-out w-full md:w-auto cursor-pointer"
        >
          <span className="absolute top-0 right-0 mt-2 mr-2 inline-flex items-center bg-blue-100 text-blue-700 text-xs font-bold px-3 py-1.5 rounded-md">
            Coming Soon
          </span>
          <CollectionIcon className="w-12 h-12 mb-3 text-purple-500" />
          <h3 className="mb-2 font-semibold">Bulk First Draft PRO</h3>
          <p className="text-sm text-center">
            Generate multiple blog posts at once by uploading a CSV of keywords
            or adding them manually. Perfect for content planning at scale.
          </p>
        </div>

        {/* New Card for Social Media */}
        <div
          onClick={handleUserOnboardingClick(
            'social-media-generator',
            'social',
            'New Social Media Content'
          )}
          className="relative flex flex-col items-center p-4 border border-gray-200 hover:border-purple-700 rounded-lg m-2 shadow-lg transform hover:bg-gray-100 hover:scale-105 transition-all duration-200 ease-in-out w-full md:w-auto cursor-pointer"
        >
          <span className="absolute top-0 right-0 mt-2 mr-2 inline-flex items-center bg-green-100 text-green-700 text-xs font-bold px-3 py-1.5 rounded-md">
            New
          </span>
          <ShareIcon className="w-12 h-12 mb-3 text-purple-500" />
          <h3 className="mb-2 font-semibold">Social Media</h3>
          <p className="text-sm text-center">
            Generate engaging social media content for multiple platforms to
            promote your blog posts and increase your online presence.
          </p>
        </div>

        {/* Writing Assistant Card */}
        <div
          onClick={handleUserOnboardingClick(
            'keyword-analyzer',
            'post',
            'New Post'
          )}
          className="flex flex-col items-center p-4 border border-gray-200 hover:border-purple-700 rounded-lg m-2 shadow-lg transform hover:bg-gray-100 hover:scale-105 transition-all duration-200 ease-in-out w-full md:w-auto cursor-pointer"
        >
          <PencilAltIcon className="w-12 h-12 mb-3 text-purple-500" />
          <h3 className="mb-2 font-semibold">Writing Assistant</h3>
          <p className="text-sm text-center">
            Guided Blog Creation: Navigate seamlessly through SEO blog post
            creation, from title generation to crafting your content.
          </p>
        </div>

        {/* Keyword Analyzer Card */}
        <div
          onClick={handleUserOnboardingClick(
            'keyword-analyzer',
            'analyzer',
            'New Search'
          )}
          className="flex flex-col items-center p-4 border border-gray-200 hover:border-purple-700 rounded-lg m-2 shadow-lg transform hover:bg-gray-100 hover:scale-105 transition-all duration-200 ease-in-out w-full md:w-auto cursor-pointer"
        >
          <KeyIcon className="w-12 h-12 mb-3 text-purple-500" />
          <h3 className="mb-2 font-semibold">Keyword Analyzer</h3>
          <p className="text-sm text-center">
            Our Keyword Analyzer give you phrases, trends, and common questions
            people are searching for online.
          </p>
        </div>

        {/* Chat Card */}
        <div
          onClick={handleUserOnboardingClick('chat', 'chat', 'New Chat')}
          className="flex flex-col items-center p-4 border border-gray-200 hover:border-purple-700 rounded-lg m-2 shadow-lg transform hover:bg-gray-100 hover:scale-105 transition-all duration-200 ease-in-out w-full md:w-auto cursor-pointer"
        >
          <ChatAlt2Icon className="w-12 h-12 mb-3 text-purple-500" />
          <h3 className="mb-2 font-semibold">Chat</h3>
          <p className="text-sm text-center">
            Nova Chat: A smart chat tool for quick answers and real-time help.
          </p>
        </div>

        {/* Quick Draft Card */}
        <div
          onClick={handleUserOnboardingClick(
            'generate-first-draft',
            'draft',
            'New First Draft'
          )}
          className="flex flex-col items-center p-4 border border-gray-200 hover:border-purple-700 rounded-lg m-2 shadow-lg transform hover:bg-gray-100 hover:scale-105 transition-all duration-200 ease-in-out w-full md:w-auto cursor-pointer"
        >
          <DocumentTextIcon className="w-12 h-12 mb-3 text-purple-500" />
          <h3 className="mb-2 font-semibold">Quick Draft</h3>
          <p className="text-sm text-center">
            One Click Draft Generator: Click once, and behold the foundation of
            your blog, ready for your personal touch.
          </p>
        </div>

        {/* Rewriter Card */}
        <div
          onClick={handleUserOnboardingClick(
            'rewrite-generator',
            'rewriter',
            'New Rewrites'
          )}
          className="relative flex flex-col items-center p-4 border border-gray-200 hover:border-purple-700 rounded-lg m-2 shadow-lg transform hover:bg-gray-100 hover:scale-105 transition-all duration-200 ease-in-out w-full md:w-auto cursor-pointer"
        >
          <span className="absolute top-0 right-0 mt-2 mr-2 inline-flex items-center bg-blue-100 text-blue-700 text-xs font-bold px-3 py-1.5 rounded-md">
            Improved
          </span>
          <SwitchHorizontalIcon className="w-12 h-12 mb-3 text-purple-500" />
          <h3 className="mb-2 font-semibold">Rewriter</h3>
          <p className="text-sm text-center">
            Utilize our blog rewriter tool to effortlessly modify your blog
            posts while preserving their original meaning.
          </p>
        </div>

        {/* Summarizer Card */}
        <div
          onClick={handleUserOnboardingClick(
            'summary-generator',
            'summarizer',
            'New Summaries'
          )}
          className="relative flex flex-col items-center p-4 border border-gray-200 hover:border-purple-700 rounded-lg m-2 shadow-lg transform hover:bg-gray-100 hover:scale-105 transition-all duration-200 ease-in-out w-full md:w-auto cursor-pointer"
        >
          <span className="absolute top-0 right-0 mt-2 mr-2 inline-flex items-center bg-blue-100 text-blue-700 text-xs font-bold px-3 py-1.5 rounded-md">
            Improved
          </span>
          <SparklesIcon className="w-12 h-12 mb-3 text-purple-500" />
          <h3 className="mb-2 font-semibold">Summarizer</h3>
          <p className="text-sm text-center">
            Employ our blog summarizer tool to succinctly condense your blog
            posts, highlighting key points.
          </p>
        </div>

        {/* Title Generator Card */}
        <div
          onClick={handleUserOnboardingClick(
            'title-generator',
            'title',
            'New Titles'
          )}
          className="flex flex-col items-center p-4 border border-gray-200 hover:border-purple-700 rounded-lg m-2 shadow-lg transform hover:bg-gray-100 hover:scale-105 transition-all duration-200 ease-in-out w-full md:w-auto cursor-pointer"
        >
          <NewspaperIcon className="w-12 h-12 mb-3 text-purple-500" />
          <h3 className="mb-2 font-semibold">Title Generator</h3>
          <p className="text-sm text-center">
            Craft compelling blog titles that pique visitor interest,
            encouraging them to click and read further.
          </p>
        </div>

        {/* Outline Generator Card */}
        <div
          onClick={handleUserOnboardingClick(
            'outline-generator',
            'outline',
            'New Outlines'
          )}
          className="flex flex-col items-center p-4 border border-gray-200 hover:border-purple-700 rounded-lg m-2 shadow-lg transform hover:bg-gray-100 hover:scale-105 transition-all duration-200 ease-in-out w-full md:w-auto cursor-pointer"
        >
          <TemplateIcon className="w-12 h-12 mb-3 text-purple-500" />
          <h3 className="mb-2 font-semibold">Outline Generator</h3>
          <p className="text-sm text-center">
            Create structured blog post outlines to expedite your writing
            process and provide a clear roadmap for your content.
          </p>
        </div>
      </div>
    </div>
  );
}

export default StartHere;
