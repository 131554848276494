import './GenerateFirstDraftPro.scss';
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, Link } from 'react-router-dom';
import { InformationCircleIcon, ArrowLeftIcon } from '@heroicons/react/outline';
import { useAlert } from 'react-alert';
import { track } from '../../services/track';
import useSentData from '../../services/useSentData';
import http from '../../services/http';
import { updateUser } from '../../store/actions/auth.actions';

import FormInput from '../../components/form/FormInput';
import LoadingIcon from '../../components/LoadingIcon';
import FormTextarea from '../../components/form/FormTextarea';
import FormContainer from './FormContainer';
import FormLabel from './FormLabel';
import TextareaWithGenerate from './TextareaWithGenerate';
import InputWithGenerate from './InputWithGenerate';
import DeleteModal from './DeleteModal';
import { numberToThousands } from '../../utils/common';

import {
  setGenerateFirstDraftField,
  setFirstDraft,
  GENERATE_FIRST_DRAFT_FIELDS,
  setOption,
} from '../../store/actions/generate-first-draft';
import {
  LABELS,
  getId,
  OUTLINE_PLACEHOLDER_2,
  INSTRUCTIONS_PLACEHOLDER,
  INSTRUCTION_TEMPLATES,
} from './utils';
import NovaRobot from '../../styles/images/generate-first-draft/nova-robot.png';
import { TONES } from '../../utils/common';
import UserProvidedOutline from './UserProvidedOutline';
import FormatOutlineButton from './FormatOutlineButton';
import OutlineFormatWarningModal from './OutlineFormatWarningModal';
import Select from '../../components/form/FormSelect';
import store from '../../store';
import NovaResearchAnimation from '../../components/NovaResearchAnimation';
import DraftGenerationModal from '../../components/DraftGenerationModal';

const BrandVoiceOption = ({ isChecked, onChange, isDisabled, tooltipText }) => (
  <div className={`flex items-center mb-4 ${isDisabled ? 'opacity-50' : ''}`}>
    <input
      type="checkbox"
      id="brand-voice-checkbox"
      className="h-4 w-4 rounded border-gray-300 text-purple-600 focus:ring-purple-600 disabled:cursor-not-allowed"
      checked={isChecked}
      onChange={onChange}
      disabled={isDisabled}
    />
    <label
      htmlFor="brand-voice-checkbox"
      className={`ml-2 text-sm flex items-center ${
        isDisabled
          ? 'text-gray-400 cursor-not-allowed'
          : 'text-gray-700 cursor-pointer'
      }`}
    >
      Use Brand Voice
      <span className="relative ml-1 group">
        <InformationCircleIcon
          className={`h-5 w-5 ${
            isDisabled ? 'text-gray-300' : 'text-gray-400'
          }`}
        />
        <span className="absolute left-1/2 -translate-x-1/2 bottom-full mb-2 w-64 p-2 bg-gray-800 text-white text-xs rounded-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300 z-10">
          {tooltipText}
        </span>
      </span>
    </label>
    {isDisabled && (
      <span className="ml-2 text-xs text-gray-400">(Not available)</span>
    )}
  </div>
);

// Add this new constant for social media options
const SUPPLEMENTARY_CONTENT_OPTIONS = [
  { key: 'includeFacebook', label: 'Facebook Post' },
  { key: 'includeLinkedIn', label: 'LinkedIn Post' },
  { key: 'includeTwitter', label: 'Twitter Post' },
  { key: 'includeInstagram', label: 'Instagram Caption' },
  { key: 'includePinterest', label: 'Pinterest Pin' },
  { key: 'includeYouTubeScript', label: 'YouTube Script' },
  { key: 'includeEmailNewsletter', label: 'Email Newsletter' },
];

const OPTION_GROUPS = [
  {
    title: 'Content Enhancements',
    options: [
      { key: 'includeTitles', label: 'Additional Titles' },
      {
        key: 'includeMetaDescriptions',
        label: 'Meta Descriptions',
      },
      { key: 'includeBoldText', label: 'Bold Important Text' },
      { key: 'includeExternalLinks', label: 'Outbound Links' },
      { key: 'includeFAQs', label: 'FAQs' },
      { key: 'includeSources', label: 'Cite Sources' },
      { key: 'includeTableOfContents', label: 'Table of Contents' },
      { key: 'includeKeyTakeaways', label: 'Key Takeaways' },
    ],
  },
  {
    title: 'Visual Elements',
    options: [{ key: 'includeImages', label: 'Images' }],
  },
];

const GenerateFirstDraftPro = () => {
  const dispatch = useDispatch();
  const { draft, ...fields } = useSelector(state => state.generateFirstDraft);
  const options = useSelector(state => state.generateFirstDraft.options);
  const history = useHistory();
  const { project_id } = useParams();
  const alert = useAlert();
  const request = useSentData();
  const state = store.getState();
  const user = state.auth.user;

  const currentProject = useSelector(state =>
    state.project.projects.find(p => p.id === parseInt(project_id))
  );
  const isUncategorizedProject =
    currentProject && currentProject.name === 'Uncategorized';
  const isBrandVoiceEmpty = !currentProject || !currentProject.brand_voice;
  const isDisabled = isUncategorizedProject || isBrandVoiceEmpty;

  const tooltipText = isUncategorizedProject
    ? 'Create a project folder to generate and use brand voice.'
    : isBrandVoiceEmpty
    ? 'Add or generate a brand voice in your project settings to use this feature.'
    : "Brand voice helps maintain consistency in your content's tone and style, aligning with your brand's personality and values. It ensures that your content sounds like it's coming from your brand.";

  const [instructionsText, setInstructionsText] = useState('');
  const [loading, setLoading] = useState({
    [GENERATE_FIRST_DRAFT_FIELDS.title]: false,
    [GENERATE_FIRST_DRAFT_FIELDS.outline]: false,
    draft: false,
    delete: false,
    formatOutline: false,
  });
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [
    isOutlineFormatWarningModalOpen,
    setIsOutlineFormatWarningModalOpen,
  ] = useState(false);
  const [isDraftGenerationModalOpen, setIsDraftGenerationModalOpen] = useState(
    false
  );

  const [firstDraftCredits, setFirstDraftCredits] = useState(
    user.first_draft_credits || 0
  );
  const [addonFirstDraftCredits, setAddonFirstDraftCredits] = useState(
    user.addon_first_draft_credits || 0
  );

  const getRemainingCredits = useCallback(() => {
    http.get(`/api/users/${user.id}`).then(userRes => {
      const data = userRes.data;
      if (!data.isAxiosError) {
        dispatch(updateUser(data));
        setFirstDraftCredits(data.first_draft_credits || 0);
        setAddonFirstDraftCredits(data.addon_first_draft_credits || 0);
      }
    });
  }, [user.id, dispatch]);

  useEffect(() => {
    getRemainingCredits();
  }, [getRemainingCredits]);

  const handleTemplateInsert = template => {
    const newText = instructionsText
      ? `${instructionsText}\n\n${INSTRUCTION_TEMPLATES[template]}`
      : INSTRUCTION_TEMPLATES[template];
    setInstructionsText(newText);
    dispatch(
      setGenerateFirstDraftField(
        GENERATE_FIRST_DRAFT_FIELDS.instructions,
        newText
      )
    );
  };

  const getPurchaseLink = () => {
    if (user.app_sumo_subscription) {
      const plan = user.app_sumo_subscription.plan.app_sumo_plan;
      if (
        [
          'bramework_tier1',
          'bramework_tier2',
          'bramework_tier3',
          'bramework_tier4',
        ].includes(plan)
      ) {
        return '/appsumo-plan';
      } else if (plan === 'bramework_ltd1') {
        return '/bramework-ltd-plan';
      } else if (plan === 'free_user') {
        return '/account';
      }
    }
    return '/plans';
  };

  const formatUserOutline = () => {
    const currentOutline = fields[GENERATE_FIRST_DRAFT_FIELDS.outline];
    const lines = currentOutline
      .split('\n')
      .map(line => line.trim())
      .filter(line => line);
    let formattedOutline = [];
    let currentH2 = '';

    lines.forEach((line, index) => {
      if (
        line.toUpperCase().startsWith('H2:') ||
        line.toUpperCase().startsWith('H3:')
      ) {
        const [prefix, ...rest] = line.split(':');
        formattedOutline.push(
          `${prefix.toUpperCase()}: ${rest.join(':').trim()}`
        );
      } else {
        if (index === 0 || !currentH2) {
          formattedOutline.push(`H2: ${line}`);
          currentH2 = line;
        } else {
          formattedOutline.push(`H3: ${line}`);
        }
      }
    });

    // Ensure first heading is H2
    if (formattedOutline.length > 0 && !formattedOutline[0].startsWith('H2:')) {
      formattedOutline[0] = `H2: ${formattedOutline[0].replace(/^H3:\s*/, '')}`;
    }

    // Ensure last heading is H2 and is "Conclusion" if it doesn't already exist
    const lastHeading = formattedOutline[formattedOutline.length - 1];
    if (!lastHeading.startsWith('H2:')) {
      const conclusionIndex = formattedOutline.findIndex(line =>
        line.toLowerCase().includes('conclusion')
      );
      if (conclusionIndex === -1) {
        formattedOutline.push(`H2: Conclusion`);
      } else {
        formattedOutline[conclusionIndex] = `H2: ${formattedOutline[
          conclusionIndex
        ].replace(/^H3:\s*/, '')}`;
      }
    }

    dispatch(
      setGenerateFirstDraftField(
        GENERATE_FIRST_DRAFT_FIELDS.outline,
        formattedOutline.join('\n')
      )
    );
  };

  const MAX_SECTIONS = 40;

  const countSections = outline => {
    // Check if outline is empty or undefined
    if (!outline || outline.trim() === '') {
      return 'Your outline has no headings. Click "Generate" to create an outline.';
    }

    // Sections are separated by newlines
    const sections = outline.split('\n');
    const numSections = sections.length;

    // Choose singular or plural form based on number of headings
    const sectionWord = numSections === 1 ? 'heading' : 'headings';

    if (numSections > MAX_SECTIONS) {
      const numToRemove = numSections - MAX_SECTIONS;
      return (
        <p>
          Your outline has {numSections} {sectionWord}.{' '}
          <span style={{ color: 'red' }}>
            Please remove {numToRemove} to limit to a maximum of {MAX_SECTIONS}.
          </span>
        </p>
      );
    } else {
      return `Your outline has ${numSections} ${sectionWord}.`;
    }
  };

  const createIsDisabled = useMemo(() => {
    const { primary_topic, title, outline, outline_id, outlineType } = fields;

    const requiredFieldsFilled = primary_topic && title && outline;

    const outlineConditionMet =
      (outlineType === 'ai-generated' && outline_id) ||
      outlineType === 'user-provided';

    return !requiredFieldsFilled || !outlineConditionMet || loading.draft;
  }, [fields, loading.draft]);

  const allowReset = useMemo(() => {
    const entriesOfRequiredFields = Object.entries(fields);
    const fieldsValues = entriesOfRequiredFields
      .filter(item => item[0] !== GENERATE_FIRST_DRAFT_FIELDS.tone)
      .map(e => {
        const [, v] = e;
        return v;
      });
    return !!fieldsValues.filter(item => item).length;
  }, [fields]);

  const resetFields = () => {
    track('First Draft PRO generator', { Clicked: 'reset' });
    const listOfFields = Object.keys(GENERATE_FIRST_DRAFT_FIELDS);
    listOfFields
      .filter(field => field !== 'tone')
      .forEach(field => {
        dispatch(
          setGenerateFirstDraftField(GENERATE_FIRST_DRAFT_FIELDS[field], '')
        );
      });
    dispatch(
      setGenerateFirstDraftField(
        GENERATE_FIRST_DRAFT_FIELDS.tone,
        'informative'
      )
    );
    dispatch(
      setGenerateFirstDraftField(
        GENERATE_FIRST_DRAFT_FIELDS.outlineType,
        'ai-generated'
      )
    );
    // Reset the options checkboxes
    const listOfOptions = Object.keys(options);
    listOfOptions.forEach(option => {
      dispatch(setOption(option, false));
    });
    // Reset the options
    dispatch(setOption('numberOfImages', 1));
    dispatch(setOption('imageStyle', 'photo'));
    dispatch(setOption('imageDimensions', '1024x768'));
    resetDraft();
  };

  const resetDraft = () => {
    dispatch(setFirstDraft(null));
  };

  const handleOptionChange = event => {
    const { name, checked } = event.target;
    dispatch(setOption(name, checked));
  };

  const handleImageOptionChange = useCallback(
    ({ target: { name, value } }) => {
      dispatch(setOption(name, value));
    },
    [dispatch]
  );

  const handleGenerateTitle = useCallback(async () => {
    const primaryTopic = fields[GENERATE_FIRST_DRAFT_FIELDS.primary_topic];
    const tone = fields[GENERATE_FIRST_DRAFT_FIELDS.tone];
    if (
      (user.subscription === undefined || user.subscription === null) &&
      !user.has_subscription &&
      !user.app_sumo_subscription &&
      !user.has_access
    ) {
      history.push(`/plans`);
      return;
    }
    if (!primaryTopic) {
      alert.error(`${LABELS.primary_topic} required!`);
      return;
    }

    setLoading(state => {
      const draft = { ...state };
      draft[GENERATE_FIRST_DRAFT_FIELDS.title] = true;
      return draft;
    });

    try {
      const res = await request.send('/api/first-draft-pro-title-generator', {
        phrase: primaryTopic,
        variations: 1,
        tone,
      });
      track('First Draft PRO generator', { Clicked: 'generate title' });
      const { content, message, success } = res;
      if (success) {
        alert.success(message);
        dispatch(
          setGenerateFirstDraftField(
            GENERATE_FIRST_DRAFT_FIELDS.title,
            content[0].output
          )
        );
      } else {
        alert.error(message);
      }
    } catch (err) {
      alert.error(err.message);
    } finally {
      setLoading(state => {
        const draft = { ...state };
        draft[GENERATE_FIRST_DRAFT_FIELDS.title] = false;
        return draft;
      });
    }
  }, [fields, dispatch, alert, request]);

  const handleGenerateOutline = useCallback(async () => {
    const primaryTopic = fields[GENERATE_FIRST_DRAFT_FIELDS.primary_topic];
    const title = fields[GENERATE_FIRST_DRAFT_FIELDS.title];
    if (
      (user.subscription === undefined || user.subscription === null) &&
      !user.has_subscription &&
      !user.app_sumo_subscription &&
      !user.has_access
    ) {
      history.push(`/plans`);
      return;
    }
    if (!primaryTopic || !title) {
      alert.error(`${LABELS.primary_topic} and ${LABELS.title} required!`);
      return;
    }

    setLoading(state => {
      const draft = { ...state };
      draft[GENERATE_FIRST_DRAFT_FIELDS.outline] = true;
      return draft;
    });

    try {
      const res = await request.send('/api/generate-outlines', {
        phrase: primaryTopic,
        title,
      });
      track('First Draft PRO generator', { Clicked: 'generate outline' });
      const { content, message, success, outline_id } = res;
      if (success) {
        alert.success(message);
        dispatch(
          setGenerateFirstDraftField(
            GENERATE_FIRST_DRAFT_FIELDS.outline,
            content?.text ?? ''
          )
        );
        dispatch(
          setGenerateFirstDraftField(
            GENERATE_FIRST_DRAFT_FIELDS.outline_id,
            outline_id
          )
        );
      } else {
        alert.error(message);
      }
    } catch (err) {
      alert.error(err.message);
    } finally {
      setLoading(state => {
        const draft = { ...state };
        draft[GENERATE_FIRST_DRAFT_FIELDS.outline] = false;
        return draft;
      });
    }
  }, [fields, dispatch, alert, request]);

  const isOutlineFormatted = outline => {
    const lines = outline.split('\n').filter(line => line.trim() !== '');
    if (lines.length < 3) return false;

    const headingPattern = /^(H[23]):(?:\s|$)/;
    return lines.every(line => {
      const match = line.match(headingPattern);
      if (!match) return false;
      return match[1] === match[1].toUpperCase();
    });
  };

  const handleCreateFirstDraft = useCallback(async () => {
    const outlineType = fields[GENERATE_FIRST_DRAFT_FIELDS.outlineType];
    const outline = fields[GENERATE_FIRST_DRAFT_FIELDS.outline];

    if (outlineType === 'user-provided' && !isOutlineFormatted(outline)) {
      setIsOutlineFormatWarningModalOpen(true);
      return;
    }

    const primaryTopic = fields[GENERATE_FIRST_DRAFT_FIELDS.primary_topic];
    const audience = fields[GENERATE_FIRST_DRAFT_FIELDS.audience];
    const tone = fields[GENERATE_FIRST_DRAFT_FIELDS.tone];
    const outlineId = fields[GENERATE_FIRST_DRAFT_FIELDS.outline_id];
    const title = fields[GENERATE_FIRST_DRAFT_FIELDS.title];
    const instructions = fields[GENERATE_FIRST_DRAFT_FIELDS.instructions];
    const brandVoice = fields[GENERATE_FIRST_DRAFT_FIELDS.brand_voice];
    if (
      (user.subscription === undefined || user.subscription === null) &&
      !user.has_subscription &&
      !user.app_sumo_subscription &&
      !user.has_access
    ) {
      history.push(`/plans`);
      return;
    }
    if (!primaryTopic || !outline || !title) {
      alert.error(
        `${LABELS.primary_topic}, ${LABELS.title}, and ${LABELS.outline} required!`
      );
      return;
    }

    setLoading(state => {
      const draft = { ...state };
      draft.draft = true;
      return draft;
    });

    try {
      const res = await request.send('/api/generate-draft-pro', {
        phrase: primaryTopic,
        audience,
        outline,
        tone,
        options,
        outline_id: outlineId,
        project_id,
        title,
        instructions,
        brand_voice: brandVoice,
        outline_type: outlineType,
      });
      track('First Draft PRO generator', {
        Clicked: 'generate first draft pro',
        Keyword: primaryTopic,
        Tone: tone,
        Audience: audience,
        Outline: outline,
        Options: options,
        Title: title,
        BrandVoice: brandVoice,
        Instructions: instructions,
        OutlineType: outlineType,
      });
      const { message, success } = res;
      if (success) {
        alert.success(message);
        dispatch(setFirstDraft(true));
        setIsDraftGenerationModalOpen(true);

        // Fetch the updated credit count
        getRemainingCredits();
      } else {
        alert.error(message);
      }
    } catch (err) {
      alert.error(err.message);
    } finally {
      setLoading(state => {
        const draft = { ...state };
        draft.draft = false;
        return draft;
      });
    }
  }, [fields, dispatch, alert, request, project_id, getRemainingCredits]);

  const handleGoToDashboard = () => {
    track('First Draft PRO generator', { Clicked: 'go to dashboard' });
    resetFields();
    resetDraft();
    history.push(`/project/${project_id}/drafts`);
  };

  const handleCreateAnotherPost = () => {
    setIsDraftGenerationModalOpen(false);
    resetFields();
    // You may want to scroll to the top of the page or perform other reset actions here
  };

  const getWordCount = () => {
    const outline = fields[GENERATE_FIRST_DRAFT_FIELDS.outline] || '';
    const outlineItems = outline
      .split('\n')
      .map(item => item.trim())
      .filter(item => item);
    let totalSubHeading = outlineItems.length || 7;
    const wordCount = 105 + totalSubHeading * 89;
    return wordCount;
  };

  const getEstimatedTime = () => {
    const timeInSeconds = getWordCount() * 0.175;
    const imageCount = options.includeImages ? options.numberOfImages : 0;
    const imageTime = imageCount * 20; // 20 seconds per image
    const totalTimeInSeconds = timeInSeconds + imageTime;
    const minutes = Math.ceil(totalTimeInSeconds / 60);
    return `It will take approximately ${minutes} minutes to generate the draft${
      imageCount > 0
        ? ` and ${imageCount} image${imageCount > 1 ? 's' : ''}`
        : ''
    }.`;
  };

  const handleOutlineChange = useCallback(
    value => {
      dispatch(
        setGenerateFirstDraftField(GENERATE_FIRST_DRAFT_FIELDS.outline, value)
      );
    },
    [dispatch]
  );

  const handleDeleteDraft = () => {
    // This function is referenced in the DeleteModal but not defined
    // Add the implementation here or remove it if not needed
    console.log('Delete draft functionality not implemented');
  };

  const [activeTab, setActiveTab] = useState('keyword');

  const renderInputForm = () => {
    switch (activeTab) {
      case 'keyword':
        return (
          <FormContainer>
            <FormLabel label={LABELS.primary_topic} required />
            <FormInput
              id={getId(LABELS.primary_topic)}
              className="w-full"
              inputClassName="rounded-lg"
              value={fields[GENERATE_FIRST_DRAFT_FIELDS.primary_topic]}
              onChange={val => {
                dispatch(
                  setGenerateFirstDraftField(
                    GENERATE_FIRST_DRAFT_FIELDS.primary_topic,
                    val
                  )
                );
              }}
              inputProps={{ placeholder: 'how to start a blog' }}
            />
          </FormContainer>
        );
      case 'document':
        return (
          <div className="bg-gray-100 border-2 border-dashed border-gray-300 rounded-lg p-6 text-center">
            <p className="text-gray-500 mb-2">
              Upload a document (Coming Soon)
            </p>
            <button
              className="px-4 py-2 bg-purple-600 text-white rounded-md opacity-50 cursor-not-allowed"
              disabled
            >
              Upload Document
            </button>
          </div>
        );
      case 'audio':
        return (
          <div className="bg-gray-100 border-2 border-dashed border-gray-300 rounded-lg p-6 text-center">
            <p className="text-gray-500 mb-2">
              Upload an audio file (Coming Soon)
            </p>
            <button
              className="px-4 py-2 bg-purple-600 text-white rounded-md opacity-50 cursor-not-allowed"
              disabled
            >
              Upload Audio
            </button>
          </div>
        );
      case 'url':
        return (
          <div className="bg-gray-100 border-2 border-dashed border-gray-300 rounded-lg p-6 text-center">
            <p className="text-gray-500 mb-2">Enter a URL (Coming Soon)</p>
            <input
              type="text"
              className="w-full p-2 border border-gray-300 rounded-md bg-white opacity-50 cursor-not-allowed"
              placeholder="https://example.com"
              disabled
            />
          </div>
        );
      case 'youtube':
        return (
          <div className="bg-gray-100 border-2 border-dashed border-gray-300 rounded-lg p-6 text-center">
            <p className="text-gray-500 mb-2">
              Enter a YouTube URL (Coming Soon)
            </p>
            <input
              type="text"
              className="w-full p-2 border border-gray-300 rounded-md bg-white opacity-50 cursor-not-allowed"
              placeholder="https://www.youtube.com/watch?v=..."
              disabled
            />
          </div>
        );
      default:
        return null;
    }
  };

  const handleCloseModal = () => {
    setIsDraftGenerationModalOpen(false);
    // You can add logic here to either redirect to the dashboard or reset the form for a new post
  };

  const handleSupplementaryContentOptionChange = key => {
    dispatch(setOption(key, !options[key]));
  };

  useEffect(() => {
    return () => {
      resetDraft();
      resetFields();
    };
  }, []);

  return (
    <div className="generate-first-draft-pro flex h-screen bg-gray-100">
      {/* Main content area */}
      <div className="flex-grow p-6 overflow-auto">
        <div className="max-w-6xl mx-auto">
          <div className="flex items-center justify-between mb-6">
            <div className="flex items-center space-x-4">
              <img src={NovaRobot} alt="Nova Robot" className="h-16 w-16" />
              <div>
                <h1 className="text-3xl font-bold text-gray-800">
                  First Draft PRO
                </h1>
              </div>
            </div>
            <button
              className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
              onClick={handleGoToDashboard}
            >
              <ArrowLeftIcon className="h-5 w-5 mr-2" />
              Back to Dashboard
            </button>
          </div>

          {/* Credits and Purchase Button Section */}
          {!user.is_editor && !user.is_manager && !user.is_viewer && (
            <div className="bg-white rounded-lg shadow-lg p-6 mb-6">
              <div className="flex justify-between items-center">
                <div>
                  <h2 className="text-xl font-semibold text-gray-700 mb-2">
                    First Draft PRO Credits
                  </h2>
                  <p className="text-3xl font-bold text-purple-600">
                    {numberToThousands(firstDraftCredits)}
                    <span className="text-lg text-gray-500 ml-1">
                      /{' '}
                      {numberToThousands(
                        user.has_trial_subscription ? 1 : addonFirstDraftCredits
                      )}
                    </span>
                  </p>
                  {firstDraftCredits === 0 && (
                    <p className="text-sm text-red-500 mt-2">
                      You've used all your credits.
                    </p>
                  )}
                </div>
                <Link
                  to={getPurchaseLink()}
                  onClick={() =>
                    track('First Draft PRO generator', { Clicked: 'purchase' })
                  }
                  className="px-6 py-3 bg-bw-green text-white rounded-md hover:bg-bw-darkGreen transition-colors text-lg font-semibold"
                >
                  {(user.subscription === undefined ||
                    user.subscription === null) &&
                  !user.has_subscription &&
                  !user.app_sumo_subscription
                    ? 'Try First Draft PRO for $1'
                    : 'Purchase More Credits'}
                </Link>
              </div>
            </div>
          )}

          {/* How it works section */}
          <div className="bg-white rounded-lg shadow-lg p-6 mb-6">
            <h2 className="text-xl font-semibold mb-4">How it works:</h2>
            <ol className="list-decimal list-inside space-y-2">
              <li>
                Enter your topic or keyword, target audience, and select the
                tone.
              </li>
              <li>
                Click 'Generate' to create an outline. Customize as needed,
                follow the format, remove irrelevant headings, and keep within a
                30-heading limit.
              </li>
              <li>Click 'Generate First Draft'.</li>
            </ol>
          </div>

          {/* New input source selector */}
          <div className="bg-white rounded-lg shadow-lg p-6 mb-6">
            <h2 className="text-xl font-semibold mb-4">Select Input Source</h2>
            <div className="flex space-x-4 mb-6">
              {['keyword', 'document', 'audio', 'url', 'youtube'].map(tab => (
                <button
                  key={tab}
                  className={`px-4 py-2 rounded-md ${
                    activeTab === tab
                      ? 'bg-purple-600 text-white'
                      : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                  }`}
                  onClick={() => setActiveTab(tab)}
                >
                  {tab.charAt(0).toUpperCase() + tab.slice(1)}
                </button>
              ))}
            </div>
            {renderInputForm()}
          </div>

          {/* Main form */}
          <div className="bg-white rounded-lg shadow-lg p-6 mb-6">
            <form onSubmit={e => e.preventDefault()}>
              {/* Title field (full width) */}
              <div className="mb-6">
                <FormContainer>
                  <FormLabel label={LABELS.title} />
                  <InputWithGenerate
                    id={getId(LABELS.title)}
                    value={fields[GENERATE_FIRST_DRAFT_FIELDS.title]}
                    onChange={val => {
                      dispatch(
                        setGenerateFirstDraftField(
                          GENERATE_FIRST_DRAFT_FIELDS.title,
                          val
                        )
                      );
                    }}
                    onGenerate={handleGenerateTitle}
                    loading={loading[GENERATE_FIRST_DRAFT_FIELDS.title]}
                    disabled={loading.draft}
                    inputProps={{
                      placeholder:
                        "How to Start a Blog: The Ultimate Beginner's Guide",
                    }}
                  />
                </FormContainer>
              </div>

              {/* Other form fields */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <FormContainer>
                  <FormLabel label={LABELS.audience} />
                  <FormInput
                    id={getId(LABELS.audience)}
                    className="w-full"
                    inputClassName="rounded-lg"
                    value={fields[GENERATE_FIRST_DRAFT_FIELDS.audience]}
                    onChange={val => {
                      dispatch(
                        setGenerateFirstDraftField(
                          GENERATE_FIRST_DRAFT_FIELDS.audience,
                          val
                        )
                      );
                    }}
                    inputProps={{
                      placeholder:
                        'passionate bloggers wanting to blog full-time',
                    }}
                  />
                </FormContainer>
                <FormContainer>
                  <FormLabel label={LABELS.tone} />
                  <Select
                    id={getId(LABELS.tone)}
                    className="w-full"
                    options={TONES}
                    value={TONES.find(
                      item =>
                        item.value === fields[GENERATE_FIRST_DRAFT_FIELDS.tone]
                    )}
                    onChange={({ value }) => {
                      dispatch(
                        setGenerateFirstDraftField(
                          GENERATE_FIRST_DRAFT_FIELDS.tone,
                          value
                        )
                      );
                    }}
                  />
                </FormContainer>
              </div>

              <FormContainer className="mt-6">
                <FormLabel label={LABELS.outline} className="mb-2" />
                <div className="mb-4 flex items-center space-x-2">
                  <button
                    className={`px-3 py-1 text-sm rounded-lg ${
                      fields[GENERATE_FIRST_DRAFT_FIELDS.outlineType] ===
                      'ai-generated'
                        ? 'bg-purple-600 text-white'
                        : 'bg-gray-200 text-gray-700'
                    }`}
                    onClick={() =>
                      dispatch(
                        setGenerateFirstDraftField(
                          GENERATE_FIRST_DRAFT_FIELDS.outlineType,
                          'ai-generated'
                        )
                      )
                    }
                  >
                    AI Researched Outline (Recommended)
                  </button>
                  <button
                    className={`px-3 py-1 text-sm rounded-lg ${
                      fields[GENERATE_FIRST_DRAFT_FIELDS.outlineType] ===
                      'user-provided'
                        ? 'bg-purple-600 text-white'
                        : 'bg-gray-200 text-gray-700'
                    }`}
                    onClick={() =>
                      dispatch(
                        setGenerateFirstDraftField(
                          GENERATE_FIRST_DRAFT_FIELDS.outlineType,
                          'user-provided'
                        )
                      )
                    }
                  >
                    My Own Outline (Beta)
                  </button>
                </div>
                <div className="relative">
                  {fields[GENERATE_FIRST_DRAFT_FIELDS.outlineType] ===
                  'ai-generated' ? (
                    loading[GENERATE_FIRST_DRAFT_FIELDS.outline] ? (
                      <NovaResearchAnimation />
                    ) : (
                      <TextareaWithGenerate
                        id={getId(LABELS.outline)}
                        value={fields[GENERATE_FIRST_DRAFT_FIELDS.outline]}
                        onChange={handleOutlineChange}
                        onGenerate={handleGenerateOutline}
                        loading={loading[GENERATE_FIRST_DRAFT_FIELDS.outline]}
                        rows={12}
                        disabled={loading.draft}
                        placeHolder={OUTLINE_PLACEHOLDER_2}
                      />
                    )
                  ) : (
                    <div className="relative">
                      <UserProvidedOutline
                        id={getId(LABELS.outline)}
                        value={fields[GENERATE_FIRST_DRAFT_FIELDS.outline]}
                        onChange={handleOutlineChange}
                        rows={12}
                      />
                      <FormatOutlineButton
                        onFormat={formatUserOutline}
                        loading={loading.formatOutline}
                        disabled={loading.draft}
                      />
                    </div>
                  )}
                </div>
                <p className="text-sm text-gray-700 mt-2">
                  {countSections(fields[GENERATE_FIRST_DRAFT_FIELDS.outline])}
                </p>
              </FormContainer>

              {/* Instructions (full width) */}
              <FormContainer className="bg-white p-4 rounded-lg shadow mb-6">
                <div className="flex justify-between items-center mb-2">
                  <FormLabel label={LABELS.instructions} optional />
                  <span className="text-xs text-gray-500">
                    {instructionsText.length}/4000
                  </span>
                </div>
                <p className="text-xs text-gray-500 mb-2">
                  Enter additional information the AI should include when
                  writing.
                </p>
                <div className="mb-2 flex flex-wrap gap-2">
                  <button
                    className="px-2 py-1 text-xs bg-purple-100 text-purple-700 rounded hover:bg-purple-200"
                    onClick={() => handleTemplateInsert('internalLinks')}
                  >
                    Internal Links
                  </button>
                  <button
                    className="px-2 py-1 text-xs bg-purple-100 text-purple-700 rounded hover:bg-purple-200"
                    onClick={() => handleTemplateInsert('statistics')}
                  >
                    Statistics
                  </button>
                  <button
                    className="px-2 py-1 text-xs bg-purple-100 text-purple-700 rounded hover:bg-purple-200"
                    onClick={() => handleTemplateInsert('companyInfo')}
                  >
                    Company Info
                  </button>
                  <button
                    className="px-2 py-1 text-xs bg-purple-100 text-purple-700 rounded hover:bg-purple-200"
                    onClick={() => handleTemplateInsert('keyPoints')}
                  >
                    Key Points
                  </button>
                  <button
                    className="px-2 py-1 text-xs bg-purple-100 text-purple-700 rounded hover:bg-purple-200"
                    onClick={() => handleTemplateInsert('exampleScenarios')}
                  >
                    Examples
                  </button>
                  <button
                    className="px-2 py-1 text-xs bg-purple-100 text-purple-700 rounded hover:bg-purple-200"
                    onClick={() => handleTemplateInsert('callToAction')}
                  >
                    Call to Action
                  </button>
                </div>
                <FormTextarea
                  value={instructionsText}
                  onChange={val => {
                    setInstructionsText(val);
                    dispatch(
                      setGenerateFirstDraftField(
                        GENERATE_FIRST_DRAFT_FIELDS.instructions,
                        val
                      )
                    );
                  }}
                  id={getId(LABELS.instructions)}
                  maxLength={4000}
                  rows={12}
                  placeHolder={INSTRUCTIONS_PLACEHOLDER}
                  hideCharCount
                />
              </FormContainer>

              {/* Options and Social Media Content (side by side) */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
                {/* Options column */}
                <div className="bg-white p-4 rounded-lg shadow">
                  <FormLabel
                    label="Content Enhancements"
                    className="mb-4"
                    optional
                  />
                  <BrandVoiceOption
                    isChecked={fields[GENERATE_FIRST_DRAFT_FIELDS.brand_voice]}
                    onChange={e => {
                      dispatch(
                        setGenerateFirstDraftField(
                          GENERATE_FIRST_DRAFT_FIELDS.brand_voice,
                          e.target.checked
                        )
                      );
                    }}
                    isDisabled={isDisabled}
                    tooltipText={tooltipText}
                  />
                  {OPTION_GROUPS.map((group, groupIndex) => (
                    <div key={groupIndex} className="mt-4">
                      <h3 className="text-sm font-medium text-gray-700 mb-2">
                        {group.title}
                      </h3>
                      <div className="space-y-2">
                        {group.options.map(({ key, label }) => (
                          <label key={key} className="flex items-center">
                            <input
                              type="checkbox"
                              className="h-4 w-4 rounded border-gray-300 text-purple-600 focus:ring-purple-600"
                              checked={options[key] || false}
                              onChange={handleOptionChange}
                              name={key}
                            />
                            <span className="ml-2 text-sm text-gray-700">
                              {label}
                            </span>
                          </label>
                        ))}
                      </div>
                    </div>
                  ))}
                  {options.includeImages && (
                    <div className="mt-4 space-y-4">
                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                          Number of Images
                        </label>
                        <input
                          type="number"
                          min="1"
                          max="10"
                          className="w-full rounded-md border-gray-300 shadow-sm focus:border-purple-300 focus:ring focus:ring-purple-200 focus:ring-opacity-50"
                          value={options.numberOfImages}
                          onChange={e =>
                            handleImageOptionChange({
                              target: {
                                name: 'numberOfImages',
                                value: parseInt(e.target.value),
                              },
                            })
                          }
                        />
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                          Image Style
                        </label>
                        <select
                          className="w-full rounded-md border-gray-300 shadow-sm focus:border-purple-300 focus:ring focus:ring-purple-200 focus:ring-opacity-50"
                          value={options.imageStyle}
                          onChange={e =>
                            handleImageOptionChange({
                              target: {
                                name: 'imageStyle',
                                value: e.target.value,
                              },
                            })
                          }
                        >
                          <option value="photo">Photo</option>
                          <option value="illustration">Illustration</option>
                          <option value="cartoon">Cartoon</option>
                          <option value="anime">Anime</option>
                          <option value="3d">3D</option>
                          <option value="sketch">Sketch</option>
                        </select>
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                          Image Dimensions
                        </label>
                        <select
                          className="w-full rounded-md border-gray-300 shadow-sm focus:border-purple-300 focus:ring focus:ring-purple-200 focus:ring-opacity-50"
                          value={options.imageDimensions}
                          onChange={e =>
                            handleImageOptionChange({
                              target: {
                                name: 'imageDimensions',
                                value: e.target.value,
                              },
                            })
                          }
                        >
                          <option value="1024x768">1024x768 (4:3)</option>
                          <option value="1344x768">1344x768 (16:9)</option>
                          <option value="1024x1024">1024x1024 (1:1)</option>
                          <option value="768x1344">768x1344 (9:16)</option>
                          <option value="600x900">600x900 (2:3)</option>
                        </select>
                      </div>
                    </div>
                  )}
                </div>

                {/* Social Media Content column */}
                <div className="bg-white p-4 rounded-lg shadow">
                  <FormLabel
                    label="Promotional Content"
                    className="mb-2"
                    optional
                  />
                  <p className="text-sm text-gray-500 mb-2">
                    Generate additional content to promote your article
                  </p>
                  <div className="space-y-2">
                    {SUPPLEMENTARY_CONTENT_OPTIONS.map(({ key, label }) => (
                      <label key={key} className="flex items-center">
                        <input
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 text-purple-600 focus:ring-purple-600"
                          checked={options[key] || false}
                          onChange={() =>
                            handleSupplementaryContentOptionChange(key)
                          }
                        />
                        <span className="ml-2 text-sm text-gray-700">
                          {label}
                        </span>
                      </label>
                    ))}
                  </div>
                </div>
              </div>

              {/* Action Buttons */}
              <div className="mt-8 flex justify-between items-center">
                <button
                  className="px-6 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300 transition-colors"
                  onClick={resetFields}
                  disabled={!allowReset}
                >
                  Reset
                </button>
                <button
                  className="px-6 py-2 bg-purple-600 text-white rounded-md hover:bg-purple-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed flex items-center"
                  onClick={handleCreateFirstDraft}
                  disabled={createIsDisabled}
                >
                  {loading.draft && (
                    <LoadingIcon className="mr-2 h-5 w-5 animate-spin" />
                  )}
                  <span>Generate First Draft</span>
                </button>
              </div>
              {fields[GENERATE_FIRST_DRAFT_FIELDS.outline] && (
                <p className="mt-2 text-xs text-gray-400 text-right">
                  (AI will generate around {numberToThousands(getWordCount())}{' '}
                  words)
                </p>
              )}
            </form>
          </div>

          {/* Example posts and help section */}
          <div className="bg-white rounded-lg shadow-lg p-6 mb-6">
            <h2 className="text-xl font-semibold mb-4">
              Check out some posts written by First Draft PRO that ranks
            </h2>
            <ul className="list-disc list-inside space-y-2 mb-4">
              <li>
                <a
                  href="https://www.bramework.com/is-blogging-still-relevant/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-purple-600 hover:text-purple-800"
                >
                  Is Blogging Still Relevant? The Answer May Surprise You!
                </a>
              </li>
              <li>
                <a
                  href="https://www.bramework.com/what-is-the-point-of-blogging/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-purple-600 hover:text-purple-800"
                >
                  What Is The Point Of Blogging? (And Why You Should Start)
                </a>
              </li>
              <li>
                <a
                  href="https://www.handytaxguy.com/are-twitch-donations-taxable/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-purple-600 hover:text-purple-800"
                >
                  Are Twitch Donations Taxable? Streamers, Don't Get Burned!
                </a>
              </li>
            </ul>
            <button
              onClick={() => {
                if (process.env.REACT_APP_INTERCOM_ID) {
                  window.Intercom('show');
                }
              }}
              className="text-purple-600 hover:text-purple-800"
            >
              👉 Click here for help!
            </button>
          </div>

          {/* Important notice */}
          <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-6">
            <p className="font-bold">Important:</p>
            <p>
              AI writers are not meant to generate publish-ready content. We
              recommend you add your own experiences, opinions, and edits so
              your article is valuable to the reader and ranks better in Google.
              Google's latest update emphasizes experience in content.
            </p>
          </div>

          {/* Results Section (if needed) */}
          {draft && (
            <div className="bg-white rounded-lg shadow-lg p-6">
              {/* Results content */}
            </div>
          )}
        </div>
      </div>

      {/* Modals */}
      <DeleteModal
        show={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onDelete={handleDeleteDraft}
        loading={loading.delete}
        feature="First Draft PRO content"
      />

      <OutlineFormatWarningModal
        isOpen={isOutlineFormatWarningModalOpen}
        onClose={() => setIsOutlineFormatWarningModalOpen(false)}
        onFormat={() => {
          setIsOutlineFormatWarningModalOpen(false);
          formatUserOutline();
        }}
      />

      <DraftGenerationModal
        isOpen={isDraftGenerationModalOpen}
        estimatedTime={getEstimatedTime()}
        onClose={handleCloseModal}
        onGoToDashboard={handleGoToDashboard}
        onCreateAnotherPost={handleCreateAnotherPost}
      />
    </div>
  );
};

export default GenerateFirstDraftPro;
