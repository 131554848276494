import './GenerateFirstDraft.scss';
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { ThumbUpIcon, ThumbDownIcon } from '@heroicons/react/outline';
import { InformationCircleIcon } from '@heroicons/react/solid';
import { TrashIcon } from '@heroicons/react/outline';
import { ArrowLeftIcon } from '@heroicons/react/outline';
import cx from 'classnames';
import { useAlert } from 'react-alert';
import { track } from '../../services/track';

import FormSelect from '../../components/form/FormSelect';
import FormInput from '../../components/form/FormInput';
import LoadingIcon from '../../components/LoadingIcon';
import ResetIcon from './ResetIcon';
import FormContainer from './FormContainer';
import FormLabel from './FormLabel';
import TextareaWithGenerate from './TextareaWithGenerate';
import InputWithGenerate from './InputWithGenerate';
import DeleteModal from './DeleteModal';
import useSentData from '../../services/useSentData';
import Loading from '../../components/Loading';
import PreviewBlock from '../PostPreview/PreviewBlock';
import { numberToThousands } from '../../utils/common';
import NovaQuickDraftAnimation from '../../components/NovaQuickDraftAnimation';

import {
  setGenerateFirstDraftField,
  setFirstDraft,
  GENERATE_FIRST_DRAFT_FIELDS,
} from '../../store/actions/generate-first-draft';
import { LABELS, getId, OUTLINE_PLACEHOLDER, getWordsCount } from './utils';
import NovaRobot from '../../styles/images/generate-first-draft/nova-robot.png';
import { isolateError } from '../../utils/api';
import { Tooltip } from '@material-ui/core';
import { TONES } from '../../utils/common';
import Select from '../../components/form/FormSelect';
import { ADDITION_KEYWORDS_KEY } from '../../components/KeywordsTagInput';
import { addSecondaryKeywords } from '../../store/actions/post_builder.actions';

// Main component
const GenerateFirstDraft = () => {
  const { draft, ...fields } = useSelector(state => state.generateFirstDraft);
  const dispatch = useDispatch();
  const history = useHistory();
  const { project_id } = useParams();
  const alert = useAlert();
  const request = useSentData();

  const [trashed, setTrashed] = useState(false);
  const [loading, setLoading] = useState({
    [GENERATE_FIRST_DRAFT_FIELDS.title]: false,
    [GENERATE_FIRST_DRAFT_FIELDS.outline]: false,
    draft: false,
    delete: false,
  });
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [bodyState, setBodyState] = useState({});

  const animationRef = useRef(null);

  const scrollToAnimation = () => {
    if (animationRef.current) {
      animationRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  useEffect(() => {
    if (loading.draft) {
      scrollToAnimation();
    }
  }, [loading.draft]);

  const createIsDisabled = useMemo(() => {
    const entriesOfRequiredFields = Object.entries(fields).filter(
      ([key]) =>
        key !== 'audience' &&
        key !== 'outline_id' &&
        key !== 'instructions' &&
        key !== 'url' &&
        key !== 'brand_voice' &&
        key !== 'outlineType'
    );
    const fieldsValues = entriesOfRequiredFields.map(e => {
      const [, v] = e;
      return v;
    });
    const fieldsInvalid = !!fieldsValues.filter(item => !item).length;
    return loading.draft || fieldsInvalid;
  }, [loading.draft, fields]);

  const allowReset = useMemo(() => {
    const entriesOfRequiredFields = Object.entries(fields);
    const fieldsValues = entriesOfRequiredFields
      .filter(item => item[0] !== GENERATE_FIRST_DRAFT_FIELDS.tone)
      .map(e => {
        const [, v] = e;
        return v;
      });
    return !!fieldsValues.filter(item => item).length;
  }, [fields]);

  const draftWordsCount = useMemo(() => {
    if (!draft) return 0;
    const titleText =
      draft.body.find(b => b.type === 'Title')?.state?.text ?? '';
    const bodyText = draft.body.find(b => b.type === 'Body')?.state?.text ?? '';
    const words = getWordsCount(titleText) + getWordsCount(bodyText);
    track('First draft generator', {
      Words: words,
    });

    return words;
  }, [draft]);

  const resetFields = () => {
    track('First draft generator', { Clicked: 'reset' });
    const listOfFields = Object.keys(GENERATE_FIRST_DRAFT_FIELDS);
    listOfFields
      .filter(field => field !== 'tone')
      .forEach(field => {
        dispatch(
          setGenerateFirstDraftField(GENERATE_FIRST_DRAFT_FIELDS[field], '')
        );
      });
    dispatch(
      setGenerateFirstDraftField(
        GENERATE_FIRST_DRAFT_FIELDS.tone,
        'informative'
      )
    );
  };

  const resetDraft = () => {
    dispatch(setFirstDraft(null));
  };

  const handleGenerateTitle = useCallback(async () => {
    const primaryTopic = fields[GENERATE_FIRST_DRAFT_FIELDS.primary_topic];
    const tone = fields[GENERATE_FIRST_DRAFT_FIELDS.tone];
    if (!primaryTopic) {
      alert.error(`${LABELS.primary_topic} required!`);
      return;
    }

    setLoading(state => {
      const draft = { ...state };
      draft[GENERATE_FIRST_DRAFT_FIELDS.title] = true;
      return draft;
    });

    try {
      const res = await request.send('/api/generate-title-generator', {
        phrase: primaryTopic,
        variations: 1,
        tone,
      });
      track('First draft generator', { Clicked: 'generate title' });
      const { content, message, success } = res;
      if (success) {
        alert.success(message);
        dispatch(
          setGenerateFirstDraftField(
            GENERATE_FIRST_DRAFT_FIELDS.title,
            content[0].output
          )
        );
      } else {
        alert.error(message);
      }
    } catch (err) {
      alert.error(err.message);
    } finally {
      setLoading(state => {
        const draft = { ...state };
        draft[GENERATE_FIRST_DRAFT_FIELDS.title] = false;
        return draft;
      });
    }
  }, [fields, dispatch, alert, request]);

  const handleGenerateOutline = useCallback(async () => {
    const primaryTopic = fields[GENERATE_FIRST_DRAFT_FIELDS.primary_topic];
    const title = fields[GENERATE_FIRST_DRAFT_FIELDS.title];
    const tone = fields[GENERATE_FIRST_DRAFT_FIELDS.tone];
    if (!primaryTopic || !title) {
      alert.error(`${LABELS.primary_topic} and ${LABELS.title} required!`);
      return;
    }

    setLoading(state => {
      const draft = { ...state };
      draft[GENERATE_FIRST_DRAFT_FIELDS.outline] = true;
      return draft;
    });

    try {
      const res = await request.send('/api/generate-outline-sections', {
        phrase: primaryTopic,
        title,
        tone,
      });
      track('First draft generator', { Clicked: 'generate outline' });
      const { content, message, success } = res;
      if (success) {
        alert.success(message);
        dispatch(
          setGenerateFirstDraftField(
            GENERATE_FIRST_DRAFT_FIELDS.outline,
            content?.text ?? ''
          )
        );
      } else {
        alert.error(message);
      }
    } catch (err) {
      alert.error(err.message);
    } finally {
      setLoading(state => {
        const draft = { ...state };
        draft[GENERATE_FIRST_DRAFT_FIELDS.outline] = false;
        return draft;
      });
    }
  }, [fields, dispatch, alert, request]);

  const handleCreateFirstDraft = useCallback(async () => {
    const primaryTopic = fields[GENERATE_FIRST_DRAFT_FIELDS.primary_topic];
    const title = fields[GENERATE_FIRST_DRAFT_FIELDS.title];
    const outline = fields[GENERATE_FIRST_DRAFT_FIELDS.outline];
    const tone = fields[GENERATE_FIRST_DRAFT_FIELDS.tone];
    if (!primaryTopic || !title || !outline) {
      alert.error(
        `${LABELS.primary_topic}, ${LABELS.title} and ${LABELS.outline} required!`
      );
      return;
    }

    setLoading(state => {
      const draft = { ...state };
      draft.draft = true;
      return draft;
    });

    try {
      const res = await request.send('/api/generate-draft', {
        phrase: primaryTopic,
        title,
        outline,
        tone,
      });
      track('First draft generator', {
        Clicked: 'generate first draft',
        Keyword: primaryTopic,
        Tone: tone,
        Title: title,
        Outline: outline,
      });
      const { content, message, success } = res;

      if (!content) {
        throw new Error(message);
      }

      const bodyState = {
        id: null,
        text: content.output.html,
        generation_id: content.generation_id,
        like_status: content.like_status,
        created_at: content.created_at,
      };

      const postBody = [
        { type: 'Title', state: { text: title } },
        { type: 'HeroImage', state: { file: null } },
        { type: 'Body', state: bodyState },
      ];

      setBodyState(bodyState);

      let analysis_data = {
        volume: null,
        competition: null,
        results: null,
        difficulty: null,
        suggested_keywords: [],
        trends: null,
        top_questions: [],
        top_related: [],
        top_posts: [],
        database: 'us',
      };
      const semrushRes = await request.send('/api/semrush', {
        keyword_phrase: primaryTopic,
        database: 'us',
      });

      if (!semrushRes.isAxiosError) {
        analysis_data = semrushRes;
      }

      const resCreatePost = await request.send(
        `/api/projects/${project_id}/posts`,
        {
          phrase: primaryTopic,
          analysis_data,
        }
      );

      const { id: newPostId } = resCreatePost;
      if (!newPostId) {
        alert.error('Failed');
        return;
      }

      const generateKeywordsResponse = await request
        .send('/api/generate-keywords', {
          phrase: primaryTopic,
        })
        .catch();
      const keywordStrings = generateKeywordsResponse?.content ?? '';
      const topics = keywordStrings ? keywordStrings.split(',') : null;

      if (topics) {
        const additionKeywords = topics.map((topic, index) => ({
          id: new Date().getTime() + index,
          text: topic,
          selected: false,
        }));
        sessionStorage.setItem(
          `${ADDITION_KEYWORDS_KEY}_${newPostId}`,
          JSON.stringify(additionKeywords)
        );
        dispatch(addSecondaryKeywords(topics));
      }

      // Save the post with generated draft content
      await request.send(
        `/api/projects/${project_id}/posts/${newPostId}`,
        {
          body: postBody,
          category: 1,
          titles: title.split(','),
          topics,
        },
        'put'
      );

      const resPost = await request.send(
        `/api/projects/${project_id}/posts/${newPostId}`,
        null,
        'get'
      );

      if (success) {
        alert.success(message);
        dispatch(setFirstDraft({ id: newPostId, body: resPost?.body }));
      } else {
        alert.error(message);
      }
    } catch (err) {
      alert.error(err.message);
    } finally {
      setLoading(state => {
        const draft = { ...state };
        draft.draft = false;
        return draft;
      });
    }
  }, [fields, dispatch, alert, request, project_id]);

  const handleGoToDashboard = () => {
    resetFields();
    resetDraft();
    history.push(`/project/${project_id}/drafts`);
  };

  const handleGoToEditor = draftId => {
    resetFields();
    resetDraft();
    track('First draft generator', { Clicked: 'go to editor' });
    history.push(`/project/${project_id}/post-builder/${draftId}`);
  };

  const handleDeleteDraftCtaClicked = () => {
    setShowDeleteModal(true);
  };

  const handleDeleteDraft = useCallback(async () => {
    setLoading(state => {
      const draft = { ...state };
      draft.delete = true;
      return draft;
    });
    track('First draft generator', { Clicked: 'delete draft' });
    try {
      await request.send(
        `/api/projects/${project_id}/posts/${draft.id}`,
        null,
        'delete'
      );
      alert.success('Deleted');
    } catch (err) {
      alert.error(err.message);
    } finally {
      setLoading(state => {
        const draft = { ...state };
        draft.delete = false;
        return draft;
      });
      setShowDeleteModal(false);
      setTrashed(pre => !pre);
      dispatch(setFirstDraft(null));
    }
  }, [draft, alert, project_id, request]);

  const updateLikeFirstDraft = status => {
    const method = 'post';
    const newBodyState = { ...bodyState };
    if (newBodyState.like_status) {
      newBodyState.like_status[0].status = status;
    } else {
      newBodyState.like_status = [{ status }];
    }
    track('First draft generator', { Clicked: status });
    setBodyState(newBodyState);
    const url = status
      ? `/api/like-first-draft`
      : '/api/like-first-draft-remove';
    const updatedData = { generation_id: bodyState.generation_id, status };
    return request
      .send(url, updatedData, method)
      .then(response => {
        if (!response.isAxiosError) {
        } else {
          alert.error(isolateError(response));
        }
      })
      .catch(err => alert.error(err.message));
  };

  const getWordCount = () => {
    const outline = fields[GENERATE_FIRST_DRAFT_FIELDS.outline] || '';
    const outlineItems = outline.split('\n');
    let totalSubHeading = outlineItems.length || 7;
    const wordCount = 150 + totalSubHeading * 115;
    return numberToThousands(wordCount);
  };

  useEffect(() => {
    return () => {
      resetDraft();
      resetFields();
    };
  }, []);

  return (
    <div className="generate-first-draft flex h-screen bg-gray-100">
      <div className="flex-grow p-6 overflow-auto">
        <div className="max-w-6xl mx-auto">
          <div className="flex items-center justify-between mb-6">
            <div className="flex items-center space-x-4">
              <img src={NovaRobot} alt="Nova Robot" className="h-16 w-16" />
              <div>
                <h1 className="text-3xl font-bold text-gray-800">
                  First Draft
                </h1>
              </div>
            </div>
            <button
              className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
              onClick={handleGoToDashboard}
            >
              <ArrowLeftIcon className="h-5 w-5 mr-2" />
              Back to Dashboard
            </button>
          </div>

          {/* How it works section */}
          <div className="bg-white rounded-lg shadow-lg p-6 mb-6">
            <h2 className="text-xl font-semibold mb-4">How it works:</h2>
            <ol className="list-decimal list-inside space-y-2">
              <li>Enter your topic or keyword and select the tone</li>
              <li>Click 'Generate' to create a title</li>
              <li>
                Click 'Generate' to create an outline, then modify as needed
              </li>
              <li>Click 'Generate Quick Draft'</li>
              <li>Review and edit the generated content</li>
            </ol>
          </div>

          {/* Main form */}
          <div className="bg-white rounded-lg shadow-lg p-6 mb-6">
            <form onSubmit={e => e.preventDefault()}>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <FormContainer>
                  <FormLabel label={LABELS.primary_topic} required />
                  <FormInput
                    id={getId(LABELS.primary_topic)}
                    className="w-full"
                    inputClassName="rounded-lg"
                    value={fields[GENERATE_FIRST_DRAFT_FIELDS.primary_topic]}
                    onChange={val => {
                      dispatch(
                        setGenerateFirstDraftField(
                          GENERATE_FIRST_DRAFT_FIELDS.primary_topic,
                          val
                        )
                      );
                    }}
                    inputProps={{ placeholder: 'how to start a blog' }}
                  />
                </FormContainer>
                <FormContainer>
                  <FormLabel label={LABELS.tone} />
                  <Select
                    id={getId(LABELS.tone)}
                    className="w-full"
                    options={TONES}
                    value={TONES.find(
                      item =>
                        item.value === fields[GENERATE_FIRST_DRAFT_FIELDS.tone]
                    )}
                    onChange={({ value }) => {
                      dispatch(
                        setGenerateFirstDraftField(
                          GENERATE_FIRST_DRAFT_FIELDS.tone,
                          value
                        )
                      );
                    }}
                  />
                </FormContainer>
              </div>
              <FormContainer className="mt-4">
                <FormLabel label={LABELS.title} />
                <InputWithGenerate
                  id={getId(LABELS.title)}
                  value={fields[GENERATE_FIRST_DRAFT_FIELDS.title]}
                  onChange={val => {
                    dispatch(
                      setGenerateFirstDraftField(
                        GENERATE_FIRST_DRAFT_FIELDS.title,
                        val
                      )
                    );
                  }}
                  onGenerate={handleGenerateTitle}
                  loading={loading[GENERATE_FIRST_DRAFT_FIELDS.title]}
                  disabled={loading.draft}
                  inputProps={{
                    placeholder:
                      "How to Start a Blog: The Ultimate Beginner's Guide",
                  }}
                />
              </FormContainer>
              <FormContainer className="mt-4">
                <FormLabel label={LABELS.outline} />
                <TextareaWithGenerate
                  id={getId(LABELS.outline)}
                  value={fields[GENERATE_FIRST_DRAFT_FIELDS.outline]}
                  onChange={val => {
                    dispatch(
                      setGenerateFirstDraftField(
                        GENERATE_FIRST_DRAFT_FIELDS.outline,
                        val
                      )
                    );
                  }}
                  onGenerate={handleGenerateOutline}
                  loading={loading[GENERATE_FIRST_DRAFT_FIELDS.outline]}
                  rows={6}
                  disabled={loading.draft}
                  placeholder={OUTLINE_PLACEHOLDER}
                />
              </FormContainer>
              <div className="flex justify-between mt-6">
                <button
                  className="px-4 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300 transition-colors"
                  onClick={resetFields}
                  disabled={!allowReset}
                >
                  Reset
                </button>
                <button
                  className="px-6 py-2 bg-purple-600 text-white rounded-md hover:bg-purple-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed flex items-center"
                  onClick={handleCreateFirstDraft}
                  disabled={createIsDisabled}
                >
                  {loading.draft && (
                    <LoadingIcon className="mr-2 h-5 w-5 animate-spin" />
                  )}
                  <span>Generate Quick Draft</span>
                </button>
              </div>
              {fields[GENERATE_FIRST_DRAFT_FIELDS.outline] && (
                <p className="mt-2 text-xs text-gray-400 text-right">
                  (AI will generate around {getWordCount()} words)
                </p>
              )}
            </form>
          </div>

          {/* Results Section */}
          {draft && (
            <div className="bg-white rounded-lg shadow-lg p-6">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-semibold text-gray-800">
                  Generated Draft
                </h2>
                <div className="flex items-center space-x-2">
                  <button
                    className="px-3 py-1 text-sm bg-purple-100 text-purple-700 rounded-md hover:bg-purple-200 transition-colors"
                    onClick={() => handleGoToEditor(draft.id)}
                  >
                    Go to Editor
                  </button>
                  <Tooltip title="Like" arrow>
                    <button
                      onClick={() => updateLikeFirstDraft(1)}
                      className={`p-1 rounded-full hover:bg-gray-100 focus:outline-none ${
                        bodyState.like_status &&
                        bodyState.like_status[0]?.status === 1
                          ? 'text-green-500'
                          : 'text-gray-500'
                      }`}
                    >
                      <ThumbUpIcon className="h-5 w-5" />
                    </button>
                  </Tooltip>
                  <Tooltip title="Dislike" arrow>
                    <button
                      onClick={() => updateLikeFirstDraft(2)}
                      className={`p-1 rounded-full hover:bg-gray-100 focus:outline-none ${
                        bodyState.like_status &&
                        bodyState.like_status[0]?.status === 2
                          ? 'text-red-500'
                          : 'text-gray-500'
                      }`}
                    >
                      <ThumbDownIcon className="h-5 w-5" />
                    </button>
                  </Tooltip>
                  <Tooltip title="Delete" arrow>
                    <button
                      onClick={handleDeleteDraftCtaClicked}
                      className="p-1 rounded-full text-gray-500 hover:bg-gray-100 focus:outline-none"
                    >
                      <TrashIcon className="h-5 w-5" />
                    </button>
                  </Tooltip>
                </div>
              </div>
              <div className="max-h-[calc(100vh-400px)] overflow-y-auto preview">
                {draft?.body.map((block, i) => (
                  <PreviewBlock block={block} key={i} />
                ))}
              </div>
              <p className="mt-4 text-sm text-gray-500">
                Words: {draftWordsCount}
              </p>
            </div>
          )}

          {/* Nova Quick Draft Animation */}
          {loading.draft && (
            <div ref={animationRef} className="mt-6">
              <NovaQuickDraftAnimation />
            </div>
          )}

          {/* Important notice */}
          <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mt-6">
            <p className="font-bold">Important:</p>
            <p>
              AI writers are not meant to generate publish-ready content. We
              recommend you add your own experiences, opinions, and edits so
              your article is valuable to the reader and ranks better in Google.
              Google's latest update emphasizes experience in content.
            </p>
          </div>
        </div>
      </div>

      {/* Delete Modal */}
      <DeleteModal
        show={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onDelete={handleDeleteDraft}
        loading={loading.delete}
        feature="Quick Draft content"
      />
    </div>
  );
};

export default GenerateFirstDraft;
