import ReactGA from 'react-ga';
// import { clarity } from 'react-microsoft-clarity';
import { saveOnBoardingInfo } from './onBoaringService';

export function track(event, extra) {
  if (window && window.mixpanel && window.mixpanel.track) {
    window.mixpanel.track(event, extra);
  }
}

export function onSignIn(user) {
  // function addTruConversionTrackingScript(userIdentity) {
  //   const script = document.createElement('script');
  //   script.type = 'text/javascript';
  //   script.textContent = `_tip.push(['_trackIdentity', '${userIdentity}']);`;
  //   document.body.appendChild(script);
  // }

  if (
    window &&
    window.mixpanel &&
    window.mixpanel.identify &&
    window.mixpanel.people &&
    window.mixpanel.people.set
  ) {
    window.mixpanel.identify(user.email);
    window.mixpanel.people.set({
      $email: user.email,
      $name: user.full_name,
      $city: user.city,
      $country_code: user.country,
      'AppSumo Plan Name': user.app_sumo_subscription
        ? user.app_sumo_subscription[1]?.app_sumo_plan
        : '',
      'Subscription Plan Name': user.subscription?.plan.name,
      'Allowed Words': user.app_sumo_subscription
        ? user.app_sumo_subscription[1]?.allowed_words
        : 3000,
    });
    track('Login');
  }
  // if (process.env.REACT_APP_MICROSOFT_CLARITY_ID) {
  //   clarity.init(process.env.REACT_APP_MICROSOFT_CLARITY_ID);
  //   // Check if Clarity has been initialized before calling its methods
  //   if (clarity.hasStarted()) {
  //     clarity.setTag('userId', user.id.toString());
  //     clarity.setTag('sumoLing', user.app_sumo_subscription ? 'Yes' : 'No');
  //     const subscriptionStatus = user.subscription?.status;
  //     const status =
  //       subscriptionStatus &&
  //       subscriptionStatus !== null &&
  //       subscriptionStatus === 'active'
  //         ? 'active'
  //         : subscriptionStatus &&
  //           subscriptionStatus !== null &&
  //           subscriptionStatus === 'cancelled'
  //         ? 'cancelled'
  //         : subscriptionStatus &&
  //           subscriptionStatus !== null &&
  //           subscriptionStatus === 'in_trial'
  //         ? 'in_trial'
  //         : subscriptionStatus &&
  //           subscriptionStatus !== null &&
  //           subscriptionStatus === 'non_renewing'
  //         ? 'non_renewing'
  //         : 'none';
  //     clarity.setTag('status', status);
  //   }
  // }
  if (process.env.REACT_APP_PRODUKTLY_CLIENT_TOKEN) {
    const subscriptionStatus = user.subscription?.status;
    let planName;
    if (user.app_sumo_subscription) {
      planName = user.app_sumo_subscription[1]?.app_sumo_plan ?? '';
    } else if (user.subscription) {
      planName = user.subscription?.plan.name ?? '';
    }
    window.Produktly.identifyUser(user.id, {
      plan: planName,
      status:
        subscriptionStatus &&
        subscriptionStatus !== null &&
        subscriptionStatus === 'active'
          ? 'active'
          : subscriptionStatus &&
            subscriptionStatus !== null &&
            subscriptionStatus === 'cancelled'
          ? 'cancelled'
          : subscriptionStatus &&
            subscriptionStatus !== null &&
            subscriptionStatus === 'in_trial'
          ? 'in_trial'
          : subscriptionStatus &&
            subscriptionStatus !== null &&
            subscriptionStatus === 'non_renewing'
          ? 'non_renewing'
          : 'none',
      createdAt: user.date_joined,
    });
  }
  // Add TruConversion tracking script
  // if (user && user.email) {
  //   addTruConversionTrackingScript(user.email);
  // }
}

export function onOnboarding(user) {
  const userInfo = {
    Profession: user.profession,
    Industry: user.industry,
    'How did you hear about Bramework?':
      user.referral !== 'Other' ? user.referral : user.otherReferral,
    'Why did you sign up for Bramework?': user?.purpose?.includes('Other')
      ? user.otherPurpose
      : user.purpose,
  };
  if (
    window &&
    window.mixpanel &&
    window.mixpanel.identify &&
    window.mixpanel.people &&
    window.mixpanel.people.set
  ) {
    window.mixpanel.identify(user.email);
    window.mixpanel.people.set(userInfo);
  }
  if (process.env.REACT_APP_INTERCOM_ID) {
    window.Intercom('boot', {
      app_id: process.env.REACT_APP_INTERCOM_ID,
      referrer: user.referral !== 'Other' ? user.referral : user.otherReferral,
      purpose: user?.purpose?.includes('Other')
        ? user.otherPurpose
        : user.purpose,
    });
  }
  saveOnBoardingInfo({
    Name: user.name,
    Email: user.email,
    id: user.id,
    ...userInfo,
  });
}

export function bloggerOnBoarding(email, userInfo) {
  if (
    window &&
    window.mixpanel &&
    window.mixpanel.identify &&
    window.mixpanel.people &&
    window.mixpanel.people.set
  ) {
    if (
      window &&
      window.mixpanel &&
      window.mixpanel.identify &&
      window.mixpanel.people &&
      window.mixpanel.people.set
    ) {
      window.mixpanel.identify(email);
      window.mixpanel.people.set(userInfo);
    }
  }
  if (process.env.REACT_APP_INTERCOM_ID) {
    window.Intercom('boot', {
      app_id: process.env.REACT_APP_INTERCOM_ID,
      ...userInfo,
    });
  }
}

export function onProfessionChange(user) {
  if (
    window &&
    window.mixpanel &&
    window.mixpanel.identify &&
    window.mixpanel.people &&
    window.mixpanel.people.set
  ) {
    window.mixpanel.identify(user.email);
    window.mixpanel.people.set({
      Profession: user.profession,
    });
  }
}

export function onIndustryChange(user) {
  if (
    window &&
    window.mixpanel &&
    window.mixpanel.identify &&
    window.mixpanel.people &&
    window.mixpanel.people.set
  ) {
    window.mixpanel.identify(user.email);
    window.mixpanel.people.set({
      Industry: user.industry,
    });
  }
}

export function onPlanChange(user) {
  if (process.env.REACT_APP_INTERCOM_ID) {
    // Intercom
    window.Intercom('update', {
      app_id: process.env.REACT_APP_INTERCOM_ID,
      email: user.email,
      status:
        user.subscription?.status &&
        user.subscription?.status !== null &&
        user.subscription?.status === 'active'
          ? 'active'
          : user.subscription?.status &&
            user.subscription?.status !== null &&
            user.subscription?.status === 'cancelled'
          ? 'cancelled'
          : user.subscription?.status &&
            user.subscription?.status !== null &&
            user.subscription?.status === 'in_trial'
          ? 'in_trial'
          : user.subscription?.status &&
            user.subscription?.status !== null &&
            user.subscription?.status === 'non_renewing'
          ? 'non_renewing'
          : 'none',
      plan_name: user.subscription.plan.name,
      plan_price: user.subscription.plan.price / 100,
      period_unit: user.subscription.plan.period_unit,
    });
  }
  if (process.env.REACT_APP_GA_TRACKING_ID) {
    // Google analytics
    ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID, {
      gaOptions: {
        userId: user.id,
      },
    });
    ReactGA.event({
      category: 'User',
      label: user.subscription.plan.name,
      value: user.subscription.plan.price / 100,
      action:
        user.subscription?.status &&
        user.subscription?.status !== null &&
        user.subscription?.status === 'active'
          ? 'active'
          : user.subscription?.status &&
            user.subscription?.status !== null &&
            user.subscription?.status === 'cancelled'
          ? 'cancelled'
          : user.subscription?.status &&
            user.subscription?.status !== null &&
            user.subscription?.status === 'in_trial'
          ? 'in_trial'
          : user.subscription?.status &&
            user.subscription?.status !== null &&
            user.subscription?.status === 'non_renewing'
          ? 'non_renewing'
          : 'none',
    });
  }
  if (
    window &&
    window.mixpanel &&
    window.mixpanel.identify &&
    window.mixpanel.people &&
    window.mixpanel.people.set
  ) {
    // Mixpanel
    window.mixpanel.identify(user.email);
    window.mixpanel.people.set({
      'Subscription Plan Name': user.subscription.plan.name,
      Status:
        user.subscription?.status &&
        user.subscription?.status !== null &&
        user.subscription?.status === 'active'
          ? 'active'
          : user.subscription?.status &&
            user.subscription?.status !== null &&
            user.subscription?.status === 'cancelled'
          ? 'cancelled'
          : user.subscription?.status &&
            user.subscription?.status !== null &&
            user.subscription?.status === 'in_trial'
          ? 'in_trial'
          : user.subscription?.status &&
            user.subscription?.status !== null &&
            user.subscription?.status === 'non_renewing'
          ? 'non_renewing'
          : 'none',
      'Allowed Words': user.subscription.plan.allowed_words,
      'Plagiarism Word Limit': user.subscription.plan.plagiarism_word_limit,
      'Plan Price': user.subscription.plan.price / 100,
      'Period Unit': user.subscription.plan.period_unit,
    });
  }
}
