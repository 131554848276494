import React, { useState, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import {
  FilmIcon,
  PlusIcon,
  DownloadIcon,
  ShareIcon,
  PencilIcon,
  TrashIcon,
  HomeIcon,
  ChevronRightIcon,
} from '@heroicons/react/outline';
import { track } from '../../services/track';

function VideoDashboard() {
  const history = useHistory();
  const [videos, setVideos] = useState([
    {
      id: 1,
      title: 'Turn Blog Posts into Captivating Videos',
      thumbnail:
        'https://www.bramework.com/wp-content/uploads/2024/11/blog-post-into-videos.png',
      duration: '3:24',
      status: 'completed',
      createdAt: '2024-11-25',
      views: 245,
      shares: 12,
    },
    // Add more sample videos as needed
  ]);

  useEffect(() => {
    track('Video Dashboard', { Clicked: 'Video Dashboard' });
  }, []);

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      {/* Breadcrumb */}
      <nav className="flex mb-8" aria-label="Breadcrumb">
        <ol className="flex items-center space-x-4">
          <li>
            <div>
              <Link
                to="/dashboard"
                className="text-gray-400 hover:text-gray-500"
              >
                <HomeIcon
                  className="flex-shrink-0 h-5 w-5"
                  aria-hidden="true"
                />
                <span className="sr-only">Home</span>
              </Link>
            </div>
          </li>
          <li>
            <div className="flex items-center">
              <ChevronRightIcon
                className="flex-shrink-0 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              <span
                className="ml-4 text-sm font-medium text-gray-500"
                aria-current="page"
              >
                Videos
              </span>
            </div>
          </li>
        </ol>
      </nav>

      {/* Header */}
      <div className="mb-8">
        <div className="flex justify-between items-center">
          <div>
            <h2 className="text-2xl font-bold text-gray-900">Your Videos</h2>
            <p className="mt-1 text-sm text-gray-500">
              Manage and track your generated videos
            </p>
            <div className="mt-2">
              <span className="inline-flex items-center px-2.5 py-0.5 rounded-md text-xs font-medium bg-blue-100 text-blue-800">
                Coming Soon
              </span>
            </div>
          </div>
          <button
            onClick={() => history.push('/videos/create')}
            disabled
            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-400 cursor-not-allowed"
          >
            <PlusIcon className="h-5 w-5 mr-2" />
            Create Video
          </button>
        </div>

        {/* Stats Overview */}
        <div className="mt-6 grid grid-cols-1 gap-5 sm:grid-cols-3">
          <div className="bg-white overflow-hidden shadow rounded-lg">
            <div className="px-4 py-5 sm:p-6">
              <dt className="text-sm font-medium text-gray-500 truncate">
                Total Videos
              </dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">
                {videos.length}
              </dd>
            </div>
          </div>
          <div className="bg-white overflow-hidden shadow rounded-lg">
            <div className="px-4 py-5 sm:p-6">
              <dt className="text-sm font-medium text-gray-500 truncate">
                Total Views
              </dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">
                {videos.reduce((acc, video) => acc + video.views, 0)}
              </dd>
            </div>
          </div>
          <div className="bg-white overflow-hidden shadow rounded-lg">
            <div className="px-4 py-5 sm:p-6">
              <dt className="text-sm font-medium text-gray-500 truncate">
                Total Shares
              </dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">
                {videos.reduce((acc, video) => acc + video.shares, 0)}
              </dd>
            </div>
          </div>
        </div>
      </div>

      {/* Video Grid */}
      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
        {videos.map(video => (
          <div
            key={video.id}
            className="bg-white overflow-hidden shadow rounded-lg hover:shadow-md transition-shadow duration-200"
          >
            {/* Thumbnail */}
            <div className="relative">
              <img
                src={video.thumbnail}
                alt={video.title}
                className="w-full h-48 object-cover"
              />
              <span className="absolute bottom-2 right-2 bg-black bg-opacity-75 text-white px-2 py-1 rounded text-xs">
                {video.duration}
              </span>
            </div>

            {/* Content */}
            <div className="px-4 py-4">
              <h3 className="text-lg font-medium text-gray-900 truncate">
                {video.title}
              </h3>
              <div className="mt-2 flex items-center text-sm text-gray-500">
                <span>Created {video.createdAt}</span>
                <span className="mx-2">•</span>
                <span>{video.views} views</span>
              </div>

              {/* Actions */}
              <div className="mt-4 flex justify-between items-center">
                <div className="flex space-x-2">
                  <button className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                    <DownloadIcon className="h-4 w-4 mr-1" />
                    Download
                  </button>
                  <button className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                    <ShareIcon className="h-4 w-4 mr-1" />
                    Share
                  </button>
                </div>
                <div className="flex space-x-2">
                  <button className="p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                    <PencilIcon className="h-5 w-5" />
                  </button>
                  <button className="p-1 rounded-full text-gray-400 hover:text-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
                    <TrashIcon className="h-5 w-5" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default VideoDashboard;
